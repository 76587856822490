function lsTest() {
  let test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
const lsWorks = lsTest();

export function setLocalStorageVariable(key: string, value: string) {
  if (lsWorks) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if (isQuotaExceeded(e)) {
        deleteAllLocalStorageVariables(["lastAdaptiveGamePlayed"]);
      }
    }
  }
}

export function deleteLocalStorageVariable(key: string | string[]) {
  if (lsWorks) {
    try {
      if(key.constructor === Array){
        for (const item of key) {
          localStorage.removeItem(item);
        }
      }else if(key.constructor === String){
        localStorage.removeItem(key);
      } 
    } catch (e) {
      //deleteAllLocalStorage(['lastAdaptiveGamePlayed']);
    }
  }
}

export function getAllStorage() {
  if (lsWorks) {
    return localStorage;
  }
}

export function deleteAllLocalStorageVariables(exceptions?: string[]) {
  if (lsWorks && localStorage.length) {
    let keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (!exceptions?.includes(key)) {
        try {
          localStorage.removeItem(key);
        } catch (e) {
          // deleteAllLocalStorage(['lastAdaptiveGamePlayed']);
        }
      }
    }
  }
}

function isQuotaExceeded(e:any) {
  var quotaExceeded = false;
  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === "NS_ERROR_DOM_QUOTA_REACHED") {
            quotaExceeded = true;
          }
          break;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
}
