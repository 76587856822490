import React, { useState, useEffect } from "react";
import "./Feedback.scss";
import StarRating from "../../components/_atoms/StarRating/StarRating";
import { sendMail, emailIsValid } from "../../helpers/emailHelper";

type FeedbackProps = {

};

const cats = ['Suggestion', 'Bug', 'Compliment']
const minDescriptionLength = 15; 
const Feedback = (p: FeedbackProps) => {

  const [rating, setRating] = useState(0);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>(''); 
  const [description, setDescription] = useState<string | undefined>(undefined); 
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
 
  useEffect(() => {
    if( rating && category && description){
      setErrors(undefined)
    } 
}, [rating, category, description]);

  const handleEmailChange = (ev: React.FormEvent<HTMLInputElement>) => { 
      setEmail(ev.currentTarget.value); 
  }
  const handleTextAreaChange = (ev: React.FormEvent<HTMLTextAreaElement>) => { 
     setDescription(ev.currentTarget.value); 
  }

  const handleSubmit = () => {
    let error: string | undefined;
     
    if(!rating){
      error = 'Please fill out the Star rating'
    }else if(!category){
      error = 'Please fill out the Feedback category'
    }else if(email && !emailIsValid(email)){
      error = 'Please fill out a correct email'
    } else if(!description){
      error = 'Please fill out Your description'
    }else if(description.length < minDescriptionLength){
      error = 'The Description is to short (min. '+minDescriptionLength+' chars)'
    }
    if(!error){ 
        let htmlPart:string[] = []; 
        htmlPart.push("<strong>Rating:</strong> " + rating);
        htmlPart.push("<strong>Category:</strong> " + category);
        htmlPart.push("<strong>Description:</strong> " + description);
        htmlPart.push("<strong>Email:</strong> " + email);   
        htmlPart.push("<strong>innerWidth:</strong> " + window.innerWidth+'x'+window.innerHeight);   
        sendMail({    
            subject:'Feedback-' + category,  
            textPart:'Feedback', 
            htmlPart:htmlPart.join('<br />'), 
            customID:'Feedback.form'
        })   
        setRating(0)
        setCategory(undefined)
        setEmail('') 
        setDescription('') 
        setErrors(undefined)
        setSuccess('Thanks you for your feedback!') 
    }else{
      setErrors(error)
    } 
  }

  return <div className="row page-feedback">
  
    <div className="card feedback-card md-1">
      <h1 className="hl">Feedback</h1>
      <div className={'manchet'}> What is your opinion of this app?</div>
          <div className={'star-rate'}>
          <StarRating
            rating={rating}
            setRating={setRating}
          />
      </div> 
      <div className={'category'}>
        <h3>Feedback category</h3>
        <div className={'btn-group'}>
          {cats.map((cat, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={'btn-1-sm' + (cat === category ? " active" : '')}
                onClick={() => setCategory(cat)}
              > {cat} </button>
            );
          })}
        </div>
      </div>
      <div className={'email input-wrp'}>  
          <input type='email' placeholder="Your email" onChange={handleEmailChange} value={email}></input>
      </div>
      <div className={'description'}> 
        <textarea 
          onChange={handleTextAreaChange}
          name="description"
          rows={5}
          value={description} 
          placeholder={'Your description*'}
         /> 
      </div>
      <button type="button" className={'btn-2-xl submit'} onClick={() => handleSubmit()}>Send</button>
      { errors ? <div className='response errors'>{errors}</div> : ( success && <div className='response success'>{success}</div>) } 
   
    </div>
   </div>
 
};

export default Feedback;