import React, { useState, useEffect, useCallback, ReactElement, useLayoutEffect } from "react";
import "./ChessBoard.scss";
import Piece from "../Piece/Piece";
import NotationType from "../../types/NotationType"; 
import AIPlayerType from "../../types/AIPlayerType";
import HumanPlayerType from "../../types/HumanPlayerType";  
 
//import gs from "../../GlobalInstanceProvider";
//const moveSound = require("./move.mp3");
//https://freesound.org/ clausnymann / Nummer12

const errorSound = new Audio(require("./error.mp3").default);
const moveSound = new Audio(require("./move.mp3").default);
const pieceCaptureSound = new Audio(require("./pieceCapture.mp3").default);

type ChessBoardProps = {
  chess?:any;
  className?: string;
  fen?: string; 
  setFen?: (fen: string) => void,
  lastMove?: { from: string, to: string, promotionPiece?: string };
  setLastMove?: (lastMove: { from: string, to: string, promotionPiece?: string } | undefined) => void;
  chessBoardIsFlipped?: boolean,
  toggleChessBoardIsFlipped?: (isFlipped: boolean | undefined) => void,
  boardIsModifiable?: boolean;
  showLegalMoves?: boolean;
  onlyAllowLegalMoves?: boolean;
  showLastMove?: boolean;
  addToNotation?: (notation : NotationType) => void; 
  AIPlayer?: AIPlayerType, 
  humanPlayer?: HumanPlayerType,
  aiMove?: { from: string, to: string, showOnBoard?: boolean };
  setAiMove?: (aiMove : undefined) => void; 
  isNotationsOutside?: boolean;
  isNotationshidden?: boolean;
  notationIndex?: number; 
  setNotationIndex?: (isFlnotationIndexipped: number | undefined) => void,
  resetBoard?: { indicateCheckMove?: boolean, activeSquare?: boolean, legalMoves?: boolean, squareDraggedOver?: boolean, showModalPromotion?: boolean, moveHint?: boolean, lastMove?: boolean, isFlipped?: boolean } | number,
  setResetBoard?: (resetBoard: { indicateCheckMove?: boolean, activeSquare?: boolean, legalMoves?: boolean, squareDraggedOver?: boolean, showModalPromotion?: boolean, moveHint?: boolean, lastMove?: boolean, isFlipped?: boolean } | undefined) => void,
  setMoveHint?:  (moveHint:  { from: string, to?: string, san?:string } | undefined) => void;
  moveHint?: { from: string, to?: string, san?:string };  
  enableSounds?: boolean
};

let moveAnimationId: number = 0;

const ChessBoard = (p: ChessBoardProps) => {
 
  const [activeSquare, setActiveSquare] = useState<HTMLDivElement | undefined>();
  const [legalMoves, setLegalMoves] = useState<string[] | null>();
  const [indicateCheckMove, setIndicateCheckMove] = useState<string | undefined>();
  const [squareDraggedOver, setSquareDraggedOver] = useState<string | undefined>(); // used for both TouchMove and Drag
  const [showModalPromotion, setShowModalPromotion] = useState<{ piece: string, from: string, to: string } | false>();
  const [modalPromotionPicker, setModalPromotionPicker] = useState<ReactElement | undefined>();
  const [animatedMove, setAnimatedMove] = useState<{ piece: string, from: string, to: string, promotion?: string } | undefined>();
  const [notations, setNotations] = useState<{ numeric: ReactElement[], alpha: ReactElement[] }>();
  
  const playSound = useCallback((sound: HTMLAudioElement ) => { 
    if(p.enableSounds !== false){ 
      let soundPromise = sound?.play();  
      soundPromise.catch(error => {
        console.log(error);
      });
    } 
  }, [p]);

  const getTurn = useCallback(() => {
    return p.fen ? p.fen.includes(' w ') ? 'w' : 'b' : 'w';
  }, [p.fen]);

  useEffect(() => { 

    if (p.chess && p.notationIndex === undefined && p.humanPlayer?.color !== getTurn() && p.aiMove) {
      
      let moves = [];
      moves = p.chess.moves({ square: p.aiMove?.from, legal: true, verbose: true }).filter((move: any) => p.aiMove?.from === move.from && p.aiMove?.to === move.to);
      if (moves.length) {
        let move: {
          piece: string,
          from: string,
          to: string,
          promotion?: string
        } = {
          piece: moves[0].piece,
          from: moves[0].from,
          to: moves[0].to
        };
        if (moves[0].promotion) {
          move.promotion = moves[0].promotion;
        }
        setAnimatedMove(move);
      }
    } else {
      setAnimatedMove(undefined)
    }
  }, [p.notationIndex, p.aiMove, getTurn, p.AIPlayer, p.humanPlayer, p.chess]);

  const pieceToAnimateRef = React.useRef<HTMLDivElement>(null);
  const squareToAnimateToRef = React.useRef<HTMLDivElement>(null);

  if (pieceToAnimateRef) {
    let piece = pieceToAnimateRef.current;
    if (piece) {
      piece.removeAttribute('style')
    } 
  }
  
  useEffect(() => {
    const resetBoard = p.resetBoard; 
    if (!Number.isFinite(resetBoard) && typeof resetBoard === "object") { 
      const setLastMove = p.setLastMove;
      const setResetBoard = p.setResetBoard;
      const toggleChessBoardIsFlipped = p.toggleChessBoardIsFlipped;
      resetBoard.indicateCheckMove && setIndicateCheckMove(undefined);
      resetBoard.activeSquare && setActiveSquare(undefined);
      resetBoard.legalMoves && setLegalMoves(undefined);
      resetBoard.squareDraggedOver && setSquareDraggedOver(undefined);
      resetBoard.showModalPromotion && setShowModalPromotion(false);
      resetBoard.lastMove && setLastMove && setLastMove(undefined);
      resetBoard.isFlipped && toggleChessBoardIsFlipped && toggleChessBoardIsFlipped(false);
      setResetBoard && setResetBoard(undefined);
    }
  }, [p.resetBoard, p.setResetBoard, p.setLastMove, p.toggleChessBoardIsFlipped]);


  const showLegalMoves = useCallback((piece?: HTMLDivElement, square?: string) => {
 
    if ( !p.onlyAllowLegalMoves) {
      return false;
    }
    setIndicateCheckMove(undefined);
    let moves:any = [];
    if (piece && square) {
      if (piece.getAttribute('color') !== getTurn()) {
        p.chess.load(p.chess.fen().replace(' ' + getTurn() + ' ', ' ' + piece.getAttribute('color') + ' '))
      }
      moves = p.chess.moves({ square: square, legal: p.onlyAllowLegalMoves, verbose: true }).map((move: any) => move.to);
    }
    setLegalMoves(moves);
    if (!moves.length && p.chess.in_check() && !p.notationIndex) {
      const moves = p.chess.moves({ verbose: true });
      if (moves.length) {
        let move = moves.find((o: any) => o.piece.toLowerCase() === 'k');
        if (move) {
          setIndicateCheckMove(move.from);
          playSound(errorSound) 
        }
      }
    } 
  }, [ p.onlyAllowLegalMoves, getTurn, playSound, p.chess, p.notationIndex]);
 
  const removePiece = useCallback((from: string) => {
    const setMoveHint = p.setMoveHint;
    const setFen = p.setFen;
    p.chess.remove(from);
    if (setFen) {
      setFen(p.chess.fen());
    }

    setActiveSquare(undefined)
    setSquareDraggedOver(undefined) 
    setMoveHint && setMoveHint(undefined)

  }, [p.setFen, p.setMoveHint, p.chess]); 

  const movePiece = useCallback((piece: string, from: string, to: string, promotion?: string) => {
    const fen = p.fen;
    const setFen = p.setFen;
    const setLastMove = p.setLastMove;
    const setMoveHint = p.setMoveHint;
    const onlyAllowLegalMoves = p.onlyAllowLegalMoves;
    const setNotationIndex = p.setNotationIndex;
    const addToNotation = p.addToNotation;

    if (from !== to) {
      if (p.chess && onlyAllowLegalMoves) {

        let move = promotion ? { from: from, to: to, promotion: promotion } : { from: from, to: to }; 
        let pieceTaken = p.chess.get(to);
        let n = p.chess.move(move); 
        let color = piece.toUpperCase() === piece ? 'w' : 'b';

        if (n) {  
          playSound(moveSound)
          if(pieceTaken){
            playSound(pieceCaptureSound) 
          } 
          let move: { from: string, to: string,  promotionPiece?: string} = { from: from, to: to }
          if(promotion){
            move.promotionPiece = promotion
          }
          setLastMove && setLastMove(move); 

          if (p.chess && setNotationIndex && addToNotation) {
            addToNotation({color: n.color, from: n.from, to: n.to, flags: n.flags, piece: (n.color === 'w' ? n.piece.toUpperCase() : n.piece), san: n.san, oldFen: fen, fen: p.chess.fen()});
            setNotationIndex(undefined);
          }
        } else if ((!n && p.humanPlayer?.color === color && piece === 'p' && to[1] === '1' && legalMoves?.includes(to)) || (!n && p.humanPlayer?.color === color && piece === 'P' && to[1] === '8' && legalMoves?.includes(to))) {
          setShowModalPromotion({ piece: piece, from: from, to: to })
        }
        if (n && setFen) {
          setFen(p.chess.fen());
        }
      } else {
        p.chess.remove(from);
        p.chess.put({ type: piece, color: (piece.toUpperCase() === piece ? 'w' : 'b') }, to);
      }
     
      setLegalMoves(undefined)
      setActiveSquare(undefined)
      setSquareDraggedOver(undefined)
      setMoveHint && setMoveHint(undefined)
    } 
  }, [p.fen, p.setFen, p.setLastMove, p.setMoveHint, p.onlyAllowLegalMoves, p.setNotationIndex, p.addToNotation, legalMoves, p.humanPlayer, playSound, p.chess]);
 
  useLayoutEffect(() => {

    if (p.aiMove && animatedMove && pieceToAnimateRef && squareToAnimateToRef) {

      cancelAnimationFrame(moveAnimationId);
      let piece = pieceToAnimateRef.current;
      let square = squareToAnimateToRef.current;

      if (piece && square) {

        const squareDomRect = square.getBoundingClientRect();
        const pieceDomRect = piece.getBoundingClientRect();

        
        let top = p.chessBoardIsFlipped ? (pieceDomRect.top - squareDomRect.top) : (squareDomRect.top - pieceDomRect.top);
        let left = p.chessBoardIsFlipped ? (pieceDomRect.left - squareDomRect.left) : (squareDomRect.left - pieceDomRect.left);

        moveAnimationId = requestAnimationFrame(() => {
          setAnimatedMove(undefined) 
          if (piece) {
            let moveAnimationTime = 650;//ms
            let delay = 1200; //ms // delay in order to let AI move smooth before rerenders kicks in..
            piece.style.zIndex = '10';
            piece.style.transition = 'transform ' + moveAnimationTime + 'ms ease-in-out ' + delay + 'ms';
            piece.style.transform = 'translate(' + left.toString() + 'px, ' + top.toString() + 'px)';
            if (p.chessBoardIsFlipped) {
              piece.style.transform += 'rotate(180deg)';
            } 
            
            piece.addEventListener('transitionend', () => {   
                 movePiece(animatedMove.piece, animatedMove.from, animatedMove.to, animatedMove.promotion);  
                 if(p.setAiMove){   
                  p.setAiMove(undefined); 
                }
            });
          }
        }); 
      }
    }
  }, [animatedMove, movePiece, p, getTurn]);
  
  useEffect(() => {
    
    const handlePromotion = (promotionPiece: string) => {
      if (showModalPromotion) { 
        movePiece(showModalPromotion.piece, showModalPromotion.from, showModalPromotion.to, promotionPiece)
        setShowModalPromotion(false);  
      }
    };

    setModalPromotionPicker(showModalPromotion ? <ModalPromotion 
      color={showModalPromotion.piece.toUpperCase() === showModalPromotion.piece ? 'w' : 'b'}
      square={showModalPromotion.to}
      handlePromotion={handlePromotion}
    /> : undefined);

  }, [showModalPromotion, movePiece]); 

  useEffect(() => {
    if (!p.isNotationshidden) {
      let notationsNumeric: ReactElement[] = [];
      let notationsAlpha: ReactElement[] = [];
      for (let y = 1; y <= 8; y++) {
        notationsNumeric.push(<Notation squareDraggedOver={squareDraggedOver} legalMoves={legalMoves} showLegalMoves={p.showLegalMoves} key={"notation-numeric-" + y} type={'numeric'} flipped={p.chessBoardIsFlipped} y={y} />);
        notationsAlpha.push(<Notation squareDraggedOver={squareDraggedOver} legalMoves={legalMoves} showLegalMoves={p.showLegalMoves} key={"notation-alpha-" + y} type={'alpha'} flipped={p.chessBoardIsFlipped} y={y} />);
      }
      setNotations({ numeric: notationsNumeric, alpha: notationsAlpha }) 
    }
  }, [p.isNotationshidden, p.chessBoardIsFlipped, p.showLegalMoves, squareDraggedOver, legalMoves]); 

    let squares: ReactElement[] = [];
    let ranks = p.fen ? p.fen.indexOf(' ') > 0 ? p.fen.substr(0, p.fen.indexOf(' ')) : p.fen : undefined;
    for (let y = 1; y <= 8; y++) {
      let skip = 0;
      for (let x = 1; x <= 8; x++) {
        let piece;
        if (ranks && skip === 0) {
          let fenPart = ranks[0];
          ranks = ranks.substring(ranks[1] === "/" ? 2 : 1);
          if (fenPart.match(/[a-z]/i)) {
            piece = fenPart;
            skip = 0;
          } else if (!isNaN(+fenPart)) {
            skip += Number(fenPart) - 1;
          }
        } else {
          skip--;
        }

        let squareId = String.fromCharCode(96 + x) + (9 - y);
 
        const squareCss = [
          ...(p.showLastMove && (p.lastMove ? [p.lastMove.from, p.lastMove.to] : []).includes(squareId) ? ['last-move'] : []),
          ...(squareId === (showModalPromotion ? showModalPromotion.from : null) ? ['hide-piece'] : []),
          ...(squareId === (showModalPromotion ? showModalPromotion.to : null) ? ['active-for-promotion'] : []),
          //..(indicateCheckMove && indicateCheckMove === squareId ? ['check-move'] : []),
          ...(p.moveHint && ([p.moveHint.from, p.moveHint.to]).includes(squareId) ? ['move-hint'] : []),
        ].join(' ');
        
        squares.push(
          <Square
            id={squareId}
            key={squareId}
            indicateCheckMove={indicateCheckMove && indicateCheckMove === squareId ? true : false}
            squareRef={animatedMove && squareId === animatedMove.to ? squareToAnimateToRef : undefined}
            squareNumber={y * x}
            color={(x - y) % 2 === 0 ? "w" : "b"}
            isDropZone={p.boardIsModifiable}
            movePiece={movePiece}
            removePiece={removePiece}
            fen={p.fen}
            isActive={String.fromCharCode(96 + x) + (9 - y) === (activeSquare ? activeSquare.id : '')}
            activeSquare={activeSquare}
            setActiveSquare={setActiveSquare}
            squareDraggedOver={squareDraggedOver}
            setSquareDraggedOver={setSquareDraggedOver}
            isLegalMove={legalMoves ? legalMoves.includes(squareId) : false}
            showLegalMoves={showLegalMoves}
            onlyAllowLegalMoves={p.onlyAllowLegalMoves}
            css={squareCss}
            modalPromotionPicker={showModalPromotion && showModalPromotion.to === squareId && modalPromotionPicker && modalPromotionPicker}
            children={piece && (
              <Piece 
                pieceRef={animatedMove?.from === squareId && animatedMove ? pieceToAnimateRef : undefined}
                type={piece}
                isMoveable={(p.boardIsModifiable && !p.humanPlayer?.color) || p.humanPlayer?.color === (piece === piece.toUpperCase() ? "w" : "b")}
                removePiece={removePiece}
                activeSquare={activeSquare}
                setActiveSquare={setActiveSquare}
                squareDraggedOver={squareDraggedOver}
                setSquareDraggedOver={setSquareDraggedOver}
                getTurn={getTurn}
                onlyAllowLegalMoves={p.onlyAllowLegalMoves}
                showLegalMoves={showLegalMoves}
                movePiece={movePiece}
              />
            )
            }
          />
        );
      }
    }
   
    //console.log('useEffectSquares')
  const boardClassNames = [
    'chessboard'
  ].join(' '); 

  const boardWrpClassNames = [
    'board-wrp', 
    ...(p.className ? [p.className] : []),
    ...(p.showLegalMoves ? ['show-legal-moves'] : []),
    ...(p.showLegalMoves && legalMoves?.length ? ['has-legal-moves'] : []),
    ...(modalPromotionPicker || (p.onlyAllowLegalMoves && getTurn() !== p.humanPlayer?.color) ? ['no-moves'] : []), //|| p.fen !== gs.chess.fen() 
    ...(p.onlyAllowLegalMoves ? [] : ['allow-all-moves']),
    ...(p.isNotationshidden ? ['notation-hide'] : []),
    ...(!p.isNotationshidden && p.isNotationsOutside ? ['notation-outside'] : (!p.isNotationshidden ? ['notation-inside'] : [])),
    ...(p.chessBoardIsFlipped ? ['flipped'] : []),
  ].join(' ');

  return (
    <div className={boardClassNames}>
      <div className={boardWrpClassNames}>
        {!p.isNotationshidden && notations?.alpha && <div className={"notation-alpha"}>{notations?.alpha}</div>}
        {!p.isNotationshidden && notations?.numeric && <div className={"notation-numeric"}>{notations?.numeric}</div>}
        <div className={"board"}>{squares}</div>
      </div>
    </div>
  );
};

const Square = React.forwardRef((p: {
  id: string;
  squareRef?: React.RefObject<HTMLDivElement>;
  indicateCheckMove:boolean,
  squareNumber: number;
  color: string;
  isDropZone?: boolean;
  movePiece: (piece: string, from: string, to: string) => void;
  removePiece: (from: string) => void,
  fen: string | undefined;
  isActive?: boolean;
  isLegalMove?: boolean;
  showLegalMoves: (piece?: HTMLDivElement, square?: string) => void;
  onlyAllowLegalMoves?: boolean;
  activeSquare?: HTMLDivElement;
  setActiveSquare: (square?: HTMLDivElement) => void;
  squareDraggedOver?: string | undefined;
  setSquareDraggedOver: (square?: string | undefined) => void;
  css: string;
  modalPromotionPicker?: React.ReactNode;
  children?: React.ReactNode;
}, ref) => {

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault(); 
    p.setSquareDraggedOver(p.id);  
  };

  const handleDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    p.setSquareDraggedOver(undefined)
  };

  const handleDrop = (
    ev: React.DragEvent<HTMLDivElement>
  ) => {
    ev.preventDefault();
    const target = ev.currentTarget as HTMLDivElement;
 
    let dropSquare = target.classList.contains('square') ? target : target.parentNode as HTMLDivElement;
    let piece = ev.dataTransfer.getData("piece");
    let from = ev.dataTransfer.getData("from");
    let to = dropSquare.id;
    if ((p.isLegalMove && p.onlyAllowLegalMoves) || !p.onlyAllowLegalMoves) {
      p.movePiece(piece, from, to);
    }
    p.setSquareDraggedOver(undefined);
  };

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>, squareId: string) => {
    ev.preventDefault();
    if (p.activeSquare && p.activeSquare.id !== squareId) {
      if ((p.isLegalMove && p.onlyAllowLegalMoves) || !p.onlyAllowLegalMoves) {
        let piece = p.activeSquare.children;
        if (piece[0]) {
          p.movePiece(piece[0].id, p.activeSquare.id, squareId);
        }
      }
    }
  };
 
  const squareClassNames = [
    'square ' + p.color,
    ...(p.squareDraggedOver && p.squareDraggedOver === p.id ? ['is-dragged-over'] : []), //|| p.fen !== gs.chess.fen() 
    ...(p.isActive ? ['active'] : []),
    ...(p.isLegalMove ? ['legal-move'] : []), 
    ...(p.css ? [p.css] : [])
  ].join(' ');

  const indicateCheckMoveRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(() => { 
    if(p.indicateCheckMove){ 
       
      const square = indicateCheckMoveRef.current;
      if(square){ 
        square.classList.remove('check-move'); 
        setTimeout(() => {
          if(square){ 
            square.classList.add('check-move');
          } 
        }, 30); 
        square.addEventListener('animationend', () => {
          square.classList.remove('check-move');  
        });
      } 
    } 
  });
  
  return (
    <div   
      ref={p.squareRef ? p.squareRef : (indicateCheckMoveRef ? indicateCheckMoveRef : null)}
      className={squareClassNames}
      id={p.id}
      onDragOver={p.isDropZone ? (ev) =>  handleDragOver(ev) : void 0}
      onDragLeave={p.isDropZone ? (ev) => handleDragLeave(ev) : void 0}
      onDrop={p.isDropZone ? (ev) => handleDrop(ev) : void 0}
      onClick={p.isDropZone ? (ev) => handleClick(ev, p.id) : void 0}
    >
      {p.children}
      {p.modalPromotionPicker}
    </div>
  );
});

const Notation = (p: { 
    squareDraggedOver?:string,
    legalMoves?:string[] | null,
    showLegalMoves?: boolean;
    type: string; 
    flipped?:boolean; 
    y: number; 
  }) => { 
    const notation = p.type === 'numeric' ? (p.flipped ? p.y : 9 - p.y) : String.fromCharCode(96 + (p.flipped ? 9 - p.y : p.y)); 
    let className: string | undefined;
    if(p.type === 'numeric'){ 
      className = [
        ...(p.squareDraggedOver && Number(p.squareDraggedOver[1]) === Number(notation) ? ['active'] : []), 
        ...(p.showLegalMoves && p.squareDraggedOver && Number(p.squareDraggedOver[1]) === Number(notation) && p.legalMoves?.includes(p.squareDraggedOver) ? ['legal'] : []), 
      ].join(' ');
    }else{  
      className = [
        ...(p.squareDraggedOver && p.squareDraggedOver[0] === notation ? ['active'] : []), 
        ...(p.showLegalMoves && p.squareDraggedOver && p.squareDraggedOver[0] === notation && p.legalMoves?.includes(p.squareDraggedOver) ? ['legal'] : []), 
      ].join(' ');
    }
  const notationClassNames = [
    'notation',
    ...(className ? [className] : []) 
  ].join(' ');

  return (
    <div className={notationClassNames}>
      {notation}
    </div>
  );
};

const ModalPromotion = (p: { 
  color: string,
  square: string,
  handlePromotion: (pieceType: string) => void,
}) => {
  const promotingColor = p.square[1] && p.square[1] === '8' ? 'w' : 'b';
  const promotionPieceTypes = promotingColor === 'w' ? ['q', 'r', 'n', 'b'] : ['b', 'n', 'r', 'q'];
  let promotionPieces = [];
  for (let i = 0; i < promotionPieceTypes.length; i++) {
    promotionPieces.push(
      <div key={i}
        className={'promotion-piece-wrp'}
        onClick={(ev) => p.handlePromotion(promotionPieceTypes[i])}>
        <Piece 
          className={'absolute-center'}
          type={p.color === 'w' ? promotionPieceTypes[i].toUpperCase() : promotionPieceTypes[i].toLowerCase()}
        />
      </div>
    )
  }
  return <div className={'promotion-picker ' + (promotingColor)}>
    {promotionPieces}
  </div>
}

export default ChessBoard;