import React, { useEffect, useContext, useState } from 'react';
import {TimedGameStateContext} from "../../../contexts/TimedGameContextManagement"; 
 
import GameType from "../../../types/GameType";        
import { secondsToTime } from "../../../helpers/timeHelper";  

import "./CountdownTimer.scss"; 
//https://codepen.io/gene7299/pen/eJeoPq

type CountDownTimerProps = {
  playerColor: string, 
  hasTurn?: boolean, 
  gameType?: GameType, 
  handleRunOutOfTime?: (playerId: string) => void  
};

 
const CountDownTimer = (p: CountDownTimerProps) => {
  
  const {setTimedGameState, timedGameState} = useContext(TimedGameStateContext) 
  const playerColor: string = p.playerColor+'PlayerTimeLeft';
 
  const [showTimeWarning, setShowTimeWarning] = useState<boolean>(false);

  useEffect(() => {

    let secondsInterval: ReturnType<typeof setTimeout> | undefined; 
    let playerTimeLeft = p.playerColor === 'w' ? timedGameState.wPlayerTimeLeft : timedGameState.bPlayerTimeLeft
    const warningTimeInSeconds: number[] = [11, 6];

    if (playerTimeLeft &&  timedGameState.timerRunning && p.hasTurn) {  
      secondsInterval = setInterval(() => { 
        if(playerTimeLeft && playerTimeLeft >= 1){ 
          if(warningTimeInSeconds?.includes(playerTimeLeft)){
            setShowTimeWarning(true)  
          }else if(showTimeWarning === true){
            setShowTimeWarning(false) 
          }  
          setTimedGameState({
            ...timedGameState, 
            [p.playerColor + 'PlayerTimeLeft'] : playerTimeLeft-1
          }) 
          //setLocalStorageVariable(playerColor + 'TimeLeft', (playerTimeLeft-1).toString()) 
        }else if(!playerTimeLeft || playerTimeLeft === 0){  
          if(p.handleRunOutOfTime){
            p.handleRunOutOfTime(p.playerColor)
          } 
          clearInterval(secondsInterval);
        }  
      }, 1000); 
      
    } else if (!p.hasTurn && secondsInterval && playerTimeLeft !== 0) {
      clearInterval(secondsInterval);
    }   
    return () => clearTimeout(secondsInterval);

  }, [p, playerColor, timedGameState, setTimedGameState, showTimeWarning]);
  
  const secondsLeft = p.playerColor === 'w' ? timedGameState.wPlayerTimeLeft : timedGameState.bPlayerTimeLeft;




  let countdownTimerClassNames = [
    "countdown-timer",
    ...(showTimeWarning ? ['timer-alert'] : []), 
    ...(secondsLeft && secondsLeft <= 10 && secondsLeft > 5? ['below-10'] : []), 
    ...(secondsLeft && secondsLeft <= 5 ? ['below-5'] : []), 
  ].join(" ");
 
  /*if(secondsLeft && [30, 10].includes(secondsLeft)){
    let eventSoundPromise = eventSound.play();
    eventSoundPromise.catch(error => { });
  }*/

  return <div className={countdownTimerClassNames}>{secondsToTime(secondsLeft)}</div>
};

export default CountDownTimer;