import React, { lazy, Suspense } from 'react'
import './App.scss';
// @ts-ignore
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";

import Play from "./pageComponents/Play/Play";
import Puzzles from "./pageComponents/Puzzles/Puzzles";
import Feedback from "./pageComponents/Feedback/Feedback";
import PrivacyPolicy from "./pageComponents/PrivacyPolicy/PrivacyPolicy";
import AppLayout from "./AppLayout";
import { hasQuery, getPath } from "./helpers/urlHelper";
import { sendMail } from "./helpers/emailHelper";
import { UserSettingsContextProvider } from './contexts/UserSettingsContextManagement'
import { TimedGameStateContextProvider } from './contexts/TimedGameContextManagement'

const NoAppView = lazy(() => import("./pageComponents/NoAppView/NoAppView"));

const isPwa = ["fullscreen", "standalone", "minimal-ui"].some((displayMode: string) => window.matchMedia('(display-mode: ' + displayMode + ')').matches)
const isApp = document.referrer.includes('android-app://');
const isTwa = isPwa || isApp;
const showApp = hasQuery('free') || isPwa || isApp;

type AppProps = {
} & typeof defaultProps;

const defaultProps = {


};
const App = (p: AppProps) => {

    if (isTwa) {
        window.onerror = function (msg, url, line, col, error) {
            let htmlPart: string[] = [];
            htmlPart.push("<strong>Message</strong> " + msg);
            htmlPart.push("<strong>Location</strong> " + window.location.href);
            htmlPart.push("<strong>Url</strong> " + url);
            htmlPart.push("<strong>Line number</strong> " + line);
            htmlPart.push("<strong>Col</strong> " + col);
            htmlPart.push("<strong>Error</strong> " + error);
            htmlPart.push("<strong>innerWidth:</strong> " + window.innerWidth + 'x' + window.innerHeight);
            htmlPart.push("<strong>isTwa:</strong> " + isTwa);
            sendMail({
                textPart: 'Error',
                subject: 'Error - ' + url,
                htmlPart: htmlPart.join('<br/>'),
                customID: 'window.onerror'
            })
        }
    }

    const routes = [
        {
            path: '/',
            text: 'Play computer',
            icon: 'icon-robot',
            component: Play,
        },
        {
            path: '/puzzles',
            text: 'Puzzles',
            icon: 'icon-puzzle',
            component: Puzzles
        },
        ...!isTwa ? [{
            path: '/feedback',
            text: 'Feedback',
            icon: 'icon-feedback',
            component: Feedback
        }] : [],
        ...!isTwa ? [{
            path: '',
            externalLink: 'https://www.buymeacoffee.com/chessdose',
            text: 'Buy me coffee',
            icon: 'icon-coffee'
        }] : [],
    ];

    if (showApp) {
        return <UserSettingsContextProvider>
            <TimedGameStateContextProvider>
                <Router>
                    <AppLayout>
                        <NavBar routes={routes} />
                        <div className={"container-fluid content-wrp"}>
                            <Switch>
                                {routes && routes.map((route, index) => (
                                    <Route exact
                                        key={'route_' + index}
                                        path={route.path && route.path}
                                        component={route.component && route.component}
                                    />
                                ))}
                            </Switch>
                        </div>
                    </AppLayout>
                </Router>
            </TimedGameStateContextProvider>
        </UserSettingsContextProvider>;

    } else {
        const path = getPath();
        console.log(path)
        if (path && path[0] === "privacy-policy") {
            return <Suspense fallback={<div className={'modal-loading'} />}><PrivacyPolicy /></Suspense>
        } else {
            return <Suspense fallback={<div className={'modal-loading'} />}><NoAppView /></Suspense>
        }
    }
};

App.defaultProps = defaultProps;
export default App;