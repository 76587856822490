import AnalyzeStatsType from "../types/AnalyzeStatsType"; 
import NotationType from "../types/NotationType"; 
import { setLocalStorageVariable } from "../helpers/localeStorageHelper"; 
//import gs from "../GlobalInstanceProvider";
import EcoType from "../types/EcoType";

const findEcoOpeningWorker = new Worker("./eco-files/findEcoOpeningWorker.js?v=1");
const Chess = require("chess.js");
const figurineToAlgebraicNotationChess = new Chess(); 

export function findEcoOpening (notations: NotationType[] | undefined, callback: (eco: EcoType) => void){ 
  if (notations && notations.length >= 1 && notations.length <= 6 ) {  
      findEcoOpeningWorker.postMessage([notations]); 
      findEcoOpeningWorker.addEventListener("message", event => {  
        if(event.data !== false){   
          const newEco: EcoType = event.data; 
          setLocalStorageVariable('eco', JSON.stringify(newEco)) 
          callback(newEco)
        } 
      }); 
  }
}

export function categorizeMove(analyzerStats:AnalyzeStatsType, showBookMove?: boolean): string | undefined {  

    if(analyzerStats?.analyzed?.san){
        let san = analyzerStats?.analyzed?.san;
        let type = 'notFound';
        if(analyzerStats?.bestMove?.san === san){  
          type = 'best'
        }else if(analyzerStats?.cpMoves && san === analyzerStats?.cpMoves[0]?.san){
          type = 'good' 
        }else if(analyzerStats?.cpMoves){  
            let cpMove = Object.values(analyzerStats.cpMoves).find((cpMove, index) => {
                return cpMove.san === san 
            }); 
            let diff = cpMove && analyzerStats.cpScore ? Math.abs(analyzerStats.cpScore - Number(cpMove?.cp)) : (analyzerStats?.cpScore && analyzerStats?.cpScoreAfter ? Number(analyzerStats?.cpScoreAfter - analyzerStats?.cpScore) : 0); 
            if(diff && diff > 50){
              type = 'good';  
            }else if(diff && diff < 0 && diff >= -100){
              type = 'inaccuracy';
            }else if(diff && diff > -199 && diff < -100){
              type = 'mistake'; 
            }else if(diff && diff < -200){
              type = 'blunder'; 
            }  
        } 
        if(showBookMove && !['blunder', 'mistake'].includes(type)){
          return 'bookmove' 
        }else{
          return type 
        }
    }
}

export function figurineToAlgebraicNotation(figurineMoves?:string[], fen?: string, fromRound?: number): string[] {
  let algebraic: string[] = [];  
  if(figurineMoves) {
    figurineToAlgebraicNotationChess.load(fen)
      algebraic = figurineMoves.map((move, i) => {  
        let moveSan:string | undefined;
        let roundNr = fromRound && i % 2 !== 1 ? fromRound + Math.floor(i/2) + '.' : (i === 0 && fromRound ? '..' : (i === 0 ? '1.' : ''))
        let fromTo = move.match(/.{1,2}/g); 
        if(fromTo && fromTo.length === 2){ 
            let n =  figurineToAlgebraicNotationChess.move({from: fromTo[0], to: fromTo[1]}); 
            if(n){
                moveSan = i !==0 || !fromRound ? n.san : '..'; 
            } 
        }
        return  `${roundNr} ${moveSan !== undefined ? moveSan : ''}` 
    }) 
  }
  return algebraic;
}