import React, { lazy, Suspense, useState, useEffect, useContext, RefObject, createRef } from "react";
import "./Puzzles.scss";
import { puzzleRanges } from "./puzzleRanges.js";
import { puzzleThemeDefinitions } from "./puzzleThemeDefinitions.js";
import ToggleButton from "../../components/_atoms/ToggleButton/ToggleButton";
/* Context */
import { UserSettingsContext } from "../../contexts/UserSettingsContextManagement";
/* Components */
import RangeSlider from "../../components/_atoms/RangeSlider/RangeSlider";
import ChessBoard from "../../components/ChessBoard/ChessBoard";
import Modal from "../../components/_atoms/Modal/Modal";
import PlayerProfile from "../../components/PlayerProfile/PlayerProfile";
/* Types */
import HumanPlayerType from "../../types/HumanPlayerType";
import PuzzleThemeType from "../../types/PuzzleThemeType";
import PuzzleResultCountsType from "../../types/PuzzleResultCountsType";
/* Helpers */
import { toJson } from "../../helpers/stringHelper";
import { getSceenSizeBreakPoint } from "../../helpers/screenSizeHelper";
import { setLocalStorageVariable, deleteLocalStorageVariable } from "../../helpers/localeStorageHelper";

/* LazyLoaded Components */
const Editprofile = lazy(() => import("../../components/EditProfile/EditProfile"));
const Chess = require("chess.js");
const chess = new Chess();

const winSound = new Audio(require("../../assets/sounds/win.mp3").default);
const lostSound = new Audio(require("../../assets/sounds/lost.mp3").default);

//deleteAllLocalStorageVariables(['lastAdaptiveGamePlayed']); 
//console.log(getAllStorage())
/*https://freesound.org/ clausnymann / Nummer12
const winSound = new Audio(require("./win.mp3").default);
const lostSound = new Audio(require("./lost.mp3").default);
const eventSound = new Audio(require("./event.mp3").default);
*/
const startFenPos = "8/8/8/8/8/8/8/8 w KQkq - 0 1";
//gs.moveAnalyzerEngine.postMessage("setoption name UCI_AnalyseMode value 1");
const rangesLength = Object.keys(puzzleRanges).length;
const levels = ['Beginner', 'Easy', 'Normal', 'Hard', 'Very hard'];

//gs.cpScoreEngine.postMessage("setoption name MultiPV value 1");

const puzzleCsvToArrayWorker = new Worker("./puzzle-files/csvToArrayWorker.js"),
    puzzleThemesCsvToArrayWorker = new Worker("./puzzle-files/csvToArrayWorker.js");

let setTimeoutGetPuzzless: ReturnType<typeof setTimeout>;
let tmpPuzzleThemeFilters: PuzzleThemeType[];
let tmpPuzzleThemeFiltersCleared: PuzzleThemeType[];
let puzzleMoveNr: number;
let puzzleMoveHintUsed: boolean;

//deleteLocalStorageVariable('puzzleResultCounts')

const Puzzles = () => {

    const { userSettings } = useContext(UserSettingsContext)

    const lsHumanPlayer = toJson(localStorage.getItem('humanPlayer'))
    const lsPuzzleNr = toJson(localStorage.getItem('puzzleNr'))
    const lsActivePuzzleThemeFilters = toJson(localStorage.getItem('activePuzzleThemeFilters'))
    const lsPuzzleResultCounts = toJson(localStorage.getItem('puzzleResultCounts'))
    const lsPuzzleDifficulty = toJson(localStorage.getItem('puzzleDifficulty'))

    const [fen, setFen] = useState<string>(startFenPos)
    const [lastMove, setLastMove] = useState<{ from: string, to: string, promotionPiece?: string } | undefined>()
    const [chessBoardIsFlipped, setChessBoardIsFlipped] = useState<boolean>(false)
    const [humanPlayer, setHumanPlayer] = useState<HumanPlayerType>(lsHumanPlayer && lsHumanPlayer.elo ? lsHumanPlayer : { title: 'Guest', color: 'w', elo: 800 })
    const [moveHint, setMoveHint] = useState<{ from: string, to?: string, san?: string } | undefined>()
    const [aiMove, setAiMove] = useState<{ from: string, to: string, promotion?: string, showOnBoard?: boolean } | undefined>()
    const [modalToOpen, setModalToOpen] = useState<{ modal: React.ReactNode, closable?: boolean, noOverlay?: true, awaiting?: boolean } | undefined>()
    const [difficulty, setDifficulty] = useState<number>(lsPuzzleDifficulty ? lsPuzzleDifficulty : 9)
    const [puzzleThemeFilters, setPuzzleThemeFilters] = useState<PuzzleThemeType[]>()
    const [activePuzzleThemeFilters, setActivePuzzleThemeFilters] = useState<string[] | undefined>(lsActivePuzzleThemeFilters)
    const [puzzlesInRange, setPuzzlesInRange] = useState<string[][]>()
    const [puzzles, setPuzzles] = useState<string[][]>()
    const [puzzleNr, setPuzzleNr] = useState<number>(lsPuzzleNr ? lsPuzzleNr : 0)
    const [puzzle, setPuzzle] = useState<string[]>()
    const [puzzleThemeFilterToggleBtnRefs, setPuzzleThemeFilterToggleBtnRefs] = useState<React.MutableRefObject<HTMLButtonElement | undefined>[]>([])
    const [puzzleInfo, setPuzzleInfo] = useState<{ info?: string, class?: string, toWinInfo?: string, hintUsed?: number, lastMove?: string } | undefined>()
    const [lastMoveClass, setLastMoveClass] = useState<string | undefined>()
    const [puzzleResultCounts, setPuzzleResultCounts] = useState<PuzzleResultCountsType[]>(lsPuzzleResultCounts ? lsPuzzleResultCounts : [])

    const [resetBoard, setResetBoard] = useState<
        | {
            indicateCheckMove?: boolean;
            activeSquare?: boolean;
            legalMoves?: boolean;
            squareDraggedOver?: boolean;
            showModalPromotion?: boolean;
            moveHint?: boolean;
            lastMove?: boolean;
            isFlipped?: boolean;
        }
        | undefined
    >();

    const level = levels[Math.floor(levels.length / (rangesLength / difficulty + .1))]
    const puzzleResultCountsInRange = puzzleResultCounts[difficulty];
    const { attempts, solved, hintUsed, failed } = puzzleResultCountsInRange ? puzzleResultCountsInRange : { attempts: 0, solved: 0, hintUsed: 0, failed: 0 };

    const activePuzzleThemeFiltersCount = activePuzzleThemeFilters ? activePuzzleThemeFilters.length : 0;

    //PuzzleId,FEN,Moves,Rating,RatingDeviation,Popularity,NbPlays,Themes,GameUrl 
    const [puzzleId, puzzleFen, puzzleMoves, puzzleRating, , , , puzzleThemes] = puzzle ? puzzle : [];
    const whiteInitialMove = puzzleFen?.includes('w')
    const puzzlesLength = puzzles ? puzzles?.length : 0

    useEffect(() => { setLocalStorageVariable('humanPlayer', JSON.stringify(humanPlayer)) }, [humanPlayer])

    useEffect(() => {
        setLastMoveClass(undefined)
        puzzleMoveNr++
    }, [fen])

    useEffect(() => {
        if (lastMove && puzzle && puzzleMoveNr && puzzleInfo) {

            const notation = lastMove.from + lastMove.to + (lastMove?.promotionPiece ? lastMove?.promotionPiece : '')
            const [, puzzleFen, puzzleMoves] = puzzle;

            if (notation !== puzzleInfo?.lastMove && chess.turn() !== (puzzleFen?.includes('w') ? 'b' : 'w') && puzzleMoveNr > 1) {

                const puzzleResultCountsInRange = puzzleResultCounts[difficulty]
                const { attempts, solved, hintUsed, failed } = puzzleResultCountsInRange ? puzzleResultCountsInRange : { attempts: 0, solved: 0, hintUsed: 0, failed: 0 };
                const moves = puzzleMoves?.split(' ')
                const isCorrect = moves[puzzleMoveNr - 1] === notation

                if (puzzleMoveNr < moves.length || !isCorrect) {

                    if (!isCorrect) {

                        setPuzzleInfo({
                            ...puzzleInfo,
                            info: 'Incorrect',
                            class: 'wrong',
                            lastMove: notation
                        })

                        setLastMoveClass('wrong')
                        const puzzleResultCountsTmp = {
                            ...puzzleResultCounts,
                            [difficulty]: {
                                attempts: attempts + 1,
                                failed: failed + 1,
                                solved: solved,
                                hintUsed: hintUsed
                            }
                        }
                        setPuzzleResultCounts(puzzleResultCountsTmp)
                        setLocalStorageVariable('puzzleResultCounts', JSON.stringify(puzzleResultCountsTmp))

                        if (userSettings.enableSounds) {
                            let eventSoundPromise = lostSound.play()
                            eventSoundPromise.catch(error => { console.log(error) })
                        }

                    } else if (moves && !(puzzleMoveNr & 1)) {

                        let move: { from: string; to: string; promotion?: string } = {
                            from: moves[puzzleMoveNr].slice(0, 2),
                            to: moves[puzzleMoveNr].slice(-2)
                        }

                        setAiMove(move)

                        setPuzzleInfo({
                            ...puzzleInfo,
                            info: Math.round(puzzleMoveNr / 2) + ' of ' + (puzzle && Math.round(moves.length / 2)) + ' correct...',
                            class: 'correct',
                            lastMove: notation
                        })

                        setLastMoveClass('correct')
                    }
                } else {

                    setPuzzleInfo({
                        ...puzzleInfo,
                        info: puzzleMoveHintUsed ? 'Solved with hint' : 'Solved',
                        class: puzzleMoveHintUsed ? 'hint-used' : 'solved',
                        lastMove: notation
                    })
                    setLastMoveClass(puzzleMoveHintUsed ? 'hint-used' : 'solved')

                    if (userSettings.enableSounds) {
                        let eventSoundPromise = winSound.play()
                        eventSoundPromise.catch(error => { console.log(error) })
                    }
                    const puzzleResultCountsTmp = {
                        ...puzzleResultCounts,
                        [difficulty]: {
                            ...puzzleResultCountsInRange,
                            attempts: attempts + 1,
                            hintUsed: puzzleMoveHintUsed ? hintUsed + 1 : hintUsed,
                            solved: !puzzleMoveHintUsed ? solved + 1 : solved,
                            failed: failed
                        }
                    }

                    setPuzzleResultCounts(puzzleResultCountsTmp)
                    setLocalStorageVariable('puzzleResultCounts', JSON.stringify(puzzleResultCountsTmp))

                }
            }
        }
    }, [lastMove, puzzle, puzzleInfo, difficulty, puzzleResultCounts, userSettings]);


    const handleShowPuzzle = (puzzles?: string[][], puzzleNr?: number) => {
        if (puzzles) {
            const puzzle: string[] | undefined = puzzles[puzzleNr ? puzzleNr : 0];

            if (puzzle) {
                setPuzzle(puzzle);
                const fen = puzzle[1]
                const moves = puzzle[2].split(' ')
                const whiteInitialMove = fen?.includes('w');
                if (moves) {
                    setResetBoard({
                        indicateCheckMove: true,
                        activeSquare: true,
                        legalMoves: true,
                        squareDraggedOver: true,
                        showModalPromotion: true,
                        moveHint: true,
                        lastMove: true
                    });

                    setChessBoardIsFlipped(whiteInitialMove)
                    chess.reset()
                    chess.load(fen)

                    const toWinInfo = (whiteInitialMove ? 'Black' : 'White') + ' to move';

                    setPuzzleInfo({
                        info: toWinInfo,
                        toWinInfo: toWinInfo
                    })

                    puzzleMoveHintUsed = false
                    puzzleMoveNr = 0;

                    setFen(fen)
                    /* THIS SHOULD NOT BE NECCESSARY */
                    setTimeout(() => {
                        puzzleMoveNr = 0
                        setAiMove({
                            from: moves[0].slice(0, 2),
                            to: moves[0].slice(-2)
                        })
                    }, 100)
                }
            }
        }
    }

    useEffect(() => {
        setLocalStorageVariable('puzzleDifficulty', JSON.stringify(difficulty))
        puzzleThemesCsvToArrayWorker.postMessage('tp' + difficulty + '.csv')
    }, [difficulty]);

    puzzleCsvToArrayWorker.onmessage = (event) => {
        if (event.data) {
            setPuzzlesInRange(event.data)
            /* check if any puzzles awailable with current 
            filters or else show modal*/
            const activeFilters: string[] | undefined = puzzleThemeFilters?.filter((filter) => {
                return filter.active;
            }).map(function (filter) { return filter.theme })

            if (activeFilters && activeFilters?.length > 0) {
                const filteredPuzzles = event.data.filter((puzzle: any) => {
                    return puzzle[7].trim().split(' ').some((puzzleTheme: any) => activeFilters?.includes(puzzleTheme))
                });

                if (filteredPuzzles.length === 0) {
                    setModalToOpen({
                        modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                            header={'Out of puzzles'}
                            isClossable={false}
                            preventRootOverflow={true}
                            setModalToOpen={setModalToOpen}
                            callToActionsBtns={
                                <>
                                    <button className={'btn-1-xl'} onClick={(ev) => clearFilters()}>Clear filters</button>
                                    <button className={'btn-2-xl'} onClick={(ev) => showFilters(tmpPuzzleThemeFilters, false)}>Set filters</button>
                                </>
                            }
                        >
                            <div className={'no-match'}>
                                The filters are hiding all puzzles within this difficulty level.
                            </div>
                        </Modal></Suspense>, closable: false
                    })
                } else {
                    setPuzzles(filteredPuzzles)
                    handleShowPuzzle(filteredPuzzles, puzzleNr)
                }
            } else {
                setPuzzles(event.data)
                handleShowPuzzle(event.data, puzzleNr)
            }
        }
    }

    puzzleThemesCsvToArrayWorker.onmessage = (event) => {
        if (event.data) {
            let data = event.data.map((value: string[]) => {
                return activePuzzleThemeFilters?.includes(value[0]) ? { theme: value[0], count: value[1], active: true } : { theme: value[0], count: value[1] }
            })
            setPuzzleThemeFilters(data)
            tmpPuzzleThemeFilters = tmpPuzzleThemeFiltersCleared = data
            setPuzzleThemeFilterToggleBtnRefs(puzzleThemeFilterToggleBtnRefs => (
                Array(data.length).fill(undefined, 0).map((_, i) => puzzleThemeFilterToggleBtnRefs[i] || createRef<HTMLButtonElement | null>())
            ));
            // this is here because themes needs to be loaded first... 
            puzzleCsvToArrayWorker.postMessage('p' + difficulty + '.csv');
        }
    }

    const handleShowMoveHint = () => {
        if (puzzle && (puzzleMoveNr & 1)) {
            let puzzleMoves = puzzle[2].split(' ');
            let move = puzzleMoves[puzzleMoveNr];
            puzzleMoveHintUsed = true;
            if (move && moveHint) {
                setMoveHint({ from: move[0] + move[1], to: move[2] + move[3] })
            } else if (move) {
                setMoveHint({ from: move[0] + move[1] })
            }
        }
    }

    const retry = () => {
        if (puzzle) {
            setResetBoard({
                indicateCheckMove: true,
                activeSquare: true,
                legalMoves: true,
                squareDraggedOver: true,
                showModalPromotion: true,
                moveHint: true,
                lastMove: true
            });
            const moves = puzzle[2].split(' ')
            let move: { from: string; to: string; promotionPiece?: string } = {
                from: moves[0].slice(0, 2),
                to: moves[0].slice(-2)
            };
            chess.reset()
            chess.load(puzzle[1])
            chess.move(move)
            setLastMove(move)
            setFen(chess.fen())
            puzzleMoveNr = 0
            setPuzzleInfo({
                ...puzzleInfo,
                info: puzzleInfo?.toWinInfo,
                class: undefined,
                lastMove: undefined
            })
            puzzleMoveHintUsed = false
        }
    }

    const difficultySliderValueChanged = (ev: React.FormEvent<HTMLInputElement>): void => {
        let difficulty = ev.currentTarget.valueAsNumber;
        clearTimeout(setTimeoutGetPuzzless)
        setTimeoutGetPuzzless = setTimeout(() => {
            setDifficulty(difficulty)
            setMoveHint(undefined)
            setPuzzleNr(0)
            setPuzzleInfo(undefined)
            setFen('');
            setResetBoard({
                indicateCheckMove: true,
                activeSquare: true,
                legalMoves: true,
                squareDraggedOver: true,
                showModalPromotion: true,
                moveHint: true,
                lastMove: true,
                isFlipped: true,
            });
        }, 470)
    }

    const restartPuzzleNr = (ev?: React.MouseEvent<HTMLButtonElement>) => {
        setPuzzleNr(0)
        deleteLocalStorageVariable('puzzleNr')
        setModalToOpen(undefined)
        handleShowPuzzle(puzzles, 0)
    }

    const levelUp = (ev?: React.MouseEvent<HTMLButtonElement>) => {
        setDifficulty(difficulty + 1)
        setPuzzleNr(0)
        deleteLocalStorageVariable('puzzleNr')
        setModalToOpen(undefined)
    }

    const nextPuzzle = (ev?: React.MouseEvent<HTMLButtonElement>) => {

        const newPuzzleNr = puzzleNr + 1

        if (puzzles && puzzles[newPuzzleNr]) {
            setPuzzleNr(newPuzzleNr)
            setMoveHint(undefined)
            setModalToOpen(undefined)
            handleShowPuzzle(puzzles, newPuzzleNr)
            setLocalStorageVariable('puzzleNr', JSON.stringify(newPuzzleNr))
        } else {
            let rangeLimitHit = difficulty >= rangesLength;
            setModalToOpen({
                modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                    header={'Out of puzzles'}
                    isClossable={false}
                    preventRootOverflow={true}
                    setModalToOpen={setModalToOpen}
                    callToActionsBtns={
                        <>
                            <button className={'btn-1-xl'} onClick={(ev) => restartPuzzleNr(ev)}>Restart</button>
                            <button className={'btn-4-xl'} onClick={(ev) => showFilters(tmpPuzzleThemeFilters, false)}>Filters</button>
                            {difficulty < rangesLength &&
                                <button className={'btn-2-xl'} onClick={(ev) => levelUp(ev)}>Level up</button>
                            }
                        </>
                    }
                >
                    <div className={'no-match'}>
                        Restart{rangeLimitHit ? ' or' : ','} edit filters{rangeLimitHit ? '.' : ''}
                        {!rangeLimitHit ? ' or increase the difficulty level.' : '.'}
                    </div>
                </Modal></Suspense>, closable: false
            })
        }
    }

    const toggleThemeSelection = (active: boolean, theme?: string) => {
        if (tmpPuzzleThemeFilters && theme) {
            tmpPuzzleThemeFilters = tmpPuzzleThemeFilters.map((themeValue) => themeValue.theme === theme ? { ...themeValue, active: active } : themeValue)
        }
    }

    const toggleAllThemeSelections = (active: boolean) => {
        puzzleThemeFilterToggleBtnRefs.forEach(function (puzzleThemeFilterToggleBtnRef, index) {
            const disabled = puzzleThemeFilterToggleBtnRef.current?.classList.contains('disabled')
            if ((!active && !disabled) || (active && disabled)) {
                puzzleThemeFilterToggleBtnRef.current?.click();
            }
        })
    }

    const saveThemeFiltes = () => {
        setMoveHint(undefined)
        if (tmpPuzzleThemeFilters) {
            setPuzzleThemeFilters(tmpPuzzleThemeFilters)
            if (puzzlesInRange) {
                let activeFilters: string[] = tmpPuzzleThemeFilters.filter((filter) => {
                    return filter.active;
                }).map(function (filter) { return filter.theme; })
                setActivePuzzleThemeFilters(activeFilters.length > 0 ? activeFilters : undefined)
                if (activeFilters.length > 0) {
                    setLocalStorageVariable('activePuzzleThemeFilters', JSON.stringify(activeFilters));
                } else {
                    deleteLocalStorageVariable('activePuzzleThemeFilters');
                }

                let filteredPuzzles = puzzlesInRange.filter((puzzle) => {
                    return puzzle[7].trim().split(' ').some(puzzleTheme => activeFilters.includes(puzzleTheme))
                });

                if (activeFilters.length === 0) {
                    clearFilters();
                } else if (activeFilters.length > 0 && filteredPuzzles.length > 0) {
                    setPuzzles(filteredPuzzles)
                    deleteLocalStorageVariable('puzzleNr')
                    setPuzzleNr(0)
                    handleShowPuzzle(filteredPuzzles, 0)
                    setModalToOpen(undefined)
                    //console.log('mod')
                } else if (activeFilters.length > 0) {
                    setModalToOpen({
                        modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                            header={'Out of puzzles'}
                            isClossable={false}
                            setModalToOpen={setModalToOpen}
                            callToActionsBtns={
                                <>
                                    <button className={'btn-1-xl'} onClick={(ev) => clearFilters()}>Clear filters</button>
                                    <button className={'btn-2-xl'} onClick={(ev) => showFilters(tmpPuzzleThemeFilters, false)}>Set filters</button>
                                </>
                            }
                        >
                            <div className={'no-match'}>
                                The filters are hiding all puzzles within this diffuculty range.
                            </div>
                        </Modal></Suspense>, closable: false
                    })
                }
            }
        } else {
            clearFilters();
        }
    }

    const clearFilters = () => {
        let filters: PuzzleThemeType[] | undefined = puzzleThemeFilters?.map((value, index) => {
            return { theme: value.theme, count: value.count }
        })
        setPuzzleThemeFilters(filters)
        setActivePuzzleThemeFilters(undefined)
        tmpPuzzleThemeFilters = tmpPuzzleThemeFiltersCleared;
        setPuzzleNr(0)
        handleShowPuzzle(puzzlesInRange, 0)
        deleteLocalStorageVariable('activePuzzleThemeFilters')
        deleteLocalStorageVariable('puzzleNr')
        setModalToOpen(undefined)
        setPuzzles(puzzlesInRange)
    }

    const showFilters = (themeFilters?: PuzzleThemeType[], closable?: boolean) => {

        let filters = themeFilters ? themeFilters : puzzleThemeFilters
        let isClossable = closable !== undefined ? closable : true;

        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Filters'}
                preventRootOverflow={true}
                headerContent={
                    <ToggleButton
                        selected={false}
                        toggleSelected={toggleAllThemeSelections}
                    />
                }
                isClossable={isClossable}
                setModalToOpen={setModalToOpen}
                callToActionsBtns={<button className={'btn-2-xl'} onClick={(ev) => saveThemeFiltes()}>Save</button>}
            >
                <ul className={'list has-list-header'}>
                    {filters && filters.map((value, index) => {

                        const puzzleCount = value.count;
                        const puzzleTheme = puzzleThemeDefinitions[value.theme.trim()];

                        return puzzleTheme && (
                            <li key={index}>
                                <ToggleButton
                                    label={{ title: puzzleTheme.theme + ' (' + puzzleCount + ')', ariaLabel: { title: puzzleTheme.description, pos: index > 1 ? 'up-left' : 'down-left' } }}
                                    selected={value.active ? true : false}
                                    id={value.theme}
                                    toggleSelected={toggleThemeSelection}
                                    // disabled={puzzleCount === 0}
                                    toggleBtnRef={puzzleThemeFilterToggleBtnRefs[index]}
                                />
                            </li>
                        )
                    })}
                </ul>
            </Modal></Suspense>, closable: isClossable
        })
    }

    const handleEditProfile = () => {
        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Your profile'}
                isClossable={true}
                setModalToOpen={setModalToOpen}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => modalChildref.current.refHandleSaveHumanPlayer()}>Save</button>}
            >
                <Editprofile
                    ref={modalChildref}
                    humanPlayer={humanPlayer}
                    setHumanPlayer={setHumanPlayer}
                    setModalToOpen={setModalToOpen}
                />
            </Modal></Suspense>, closable: true
        })
    }

    const pagePuzzlesClass = [
        'page-puzzless',
        ...(userSettings.boardTheme ? ['theme-' + userSettings.boardTheme] : []),
        ...(userSettings.pieceType ? ['piece-' + userSettings.pieceType] : []),
    ].join(' ');

    const pagePuzzleChessBoardToolsClass = [
        'btn-group',
        'chessboard-tools',
        ...(!userSettings.showHintButton ? ['no-hint-btn'] : []),
    ].join(' ');

    const showToolTips = ['lg', 'xl', 'xxl'].includes(getSceenSizeBreakPoint());
    const dataBalloonPos = showToolTips ? 'up' : null;

    return (
        <div className={pagePuzzlesClass}>
            <div className={'chessboard-wrp'} role={'main'}>
                <ChessBoard
                    className={lastMoveClass ? lastMoveClass + '-last-move' : ''}
                    fen={fen}
                    setFen={setFen}
                    lastMove={lastMove}
                    setLastMove={setLastMove}
                    chessBoardIsFlipped={chessBoardIsFlipped}
                    //toggleChessBoardIsFlipped={toggleChessBoardIsFlipped}
                    boardIsModifiable={true}
                    showLegalMoves={userSettings.showLegalMoves}
                    onlyAllowLegalMoves={true}
                    showLastMove={userSettings.showLastMove}
                    humanPlayer={{
                        ...humanPlayer,
                        color: (puzzle && whiteInitialMove ? 'b' : 'w') //'w' | 'b' 
                    }}
                    aiMove={aiMove}
                    setAiMove={setAiMove}
                    isNotationshidden={!userSettings.showBoardNotations}
                    isNotationsOutside={userSettings.showNotationOutsideOfBoard}
                    moveHint={moveHint}
                    setMoveHint={setMoveHint}
                    resetBoard={resetBoard}
                    chess={chess}
                    enableSounds={userSettings.enableSounds}
                />
                <div className={pagePuzzleChessBoardToolsClass}>
                    {puzzleInfo?.class && ['wrong', 'hint-used'].includes(puzzleInfo?.class) ?
                        <button aria-label="Retry puzzle" data-balloon-pos={dataBalloonPos} onClick={(ev) => { retry() }} className={"btn-1-lg icon-undo" + (puzzleInfo?.class === 'wrong' ? ' highlight' : '')}></button>
                        : ''}
                    {(userSettings.showHintButton && puzzleInfo?.class && !['wrong', 'solved', 'hint-used'].includes(puzzleInfo?.class)) || (userSettings.showHintButton && !puzzleInfo?.class) ?
                        <button tabIndex={2} aria-label="Get a Hint" data-balloon-pos={dataBalloonPos} onClick={(ev) => { handleShowMoveHint() }} className={"btn-1-lg icon-bulp"}></button>
                        : ''}
                    {puzzleInfo?.class && ['solved', 'hint-used'].includes(puzzleInfo?.class) ?
                        <button aria-label={"Next puzzle"} data-balloon-pos={dataBalloonPos} onClick={(ev) => nextPuzzle(ev)} className={'btn-1-lg icon-chevron-right highlight'}></button>
                        : ''}
                    {(puzzleInfo?.class && ['wrong', 'correct'].includes(puzzleInfo?.class.toString())) || !puzzleInfo?.class ?
                        <button aria-label={"Skip puzzle"} data-balloon-pos={dataBalloonPos} onClick={(ev) => nextPuzzle(ev)} className={'btn-1-lg icon-chevron-right'}></button>
                        : ''}
                </div>
            </div>
            <div className={'info-wrp'} role={'main'}>
                <div className={'card puzzle-info empty-preloader preloader-v2' + (puzzleInfo?.class ? ' ' + puzzleInfo.class : '')}>
                    {puzzleInfo?.info}
                </div>
                <div className={'card player-puzzle-stats'}>
                    {humanPlayer &&
                        <PlayerProfile
                            avatarSvg={humanPlayer.avatarSvg}
                            playerColor={puzzle && whiteInitialMove ? 'b' : 'w'}
                            handleEditProfile={handleEditProfile ? () => { handleEditProfile && handleEditProfile() } : undefined}
                            winner={puzzleInfo?.class === 'solved'}
                        />
                    }
                    <div className={'info-box player-info'}>
                        <div className={'title'}>{humanPlayer.title}</div>
                        <div className='info games-played' aria-label={'Attempts in ' + level.toLowerCase() + ' level'} data-balloon-pos='bottom-left'>
                            {attempts}
                            <div className='info-label'>Attempts</div>
                        </div>
                    </div>
                    <div className={'puzzle-results'}>
                        <div className={'info-box solved'} aria-label={'Solved (' + (solved ? solved : 0) + ')'} data-balloon-pos='down-left'>
                            <div className={'info-label'}>Solved</div>
                            <div className={'info bar'}>
                                <div className={'pct solved'} style={{ width: (solved ? ((solved / attempts) * 100) + '%' : '0') }}></div>
                            </div>
                        </div>
                        <div className={'info-box solved-with-hint'} aria-label={'Solved using hints (' + (hintUsed ? hintUsed : 0) + ')'} data-balloon-pos='down-left'>
                            <div className={'info-label'}>Hint used</div>
                            <div className={'info bar'}>
                                <div className={'pct hint-used'} style={{ width: (hintUsed ? ((hintUsed / attempts) * 100) + '%' : '0') }}></div>
                            </div>
                        </div>
                        <div className={'info-box failed'} aria-label={'Failed (' + (failed ? failed : 0) + ')'} data-balloon-pos='down-left'>
                            <div className={'info-label'}>Failed</div>
                            <div className={'info bar'}>
                                <div className={'pct failed'} style={{ width: (failed ? ((failed / attempts) * 100) + '%' : '0') }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'card'}>
                    <h5>Filter puzzles</h5>
                    <div className={'grid-group filter-wrp'}>
                        <div>
                            <RangeSlider
                                min={1}
                                max={rangesLength}
                                value={difficulty}
                                step={1}
                                tabIndex={6}
                                onChange={(ev: React.FormEvent<HTMLInputElement>) => difficultySliderValueChanged(ev)}
                                syncOnValueChange={true}
                                labels={{ min: 'Min. Difficulty', max: 'Max.' }}
                            />
                        </div>
                        <label className={'range-label'}>
                            <div className='level'>{level} </div>
                            <div className='range'>{puzzleRanges[difficulty - 1].min}-{puzzleRanges[difficulty - 1].max}</div>
                        </label>
                        <button aria-label='Filter themes' data-balloon-pos='down-right' tabIndex={7} onClick={(ev) => { showFilters() }} className={'btn-7-xs icon-filter-b' + (activePuzzleThemeFiltersCount ? ' active' : '')}>
                            {activePuzzleThemeFiltersCount > 0 && <div className="count">{activePuzzleThemeFiltersCount}</div>}
                        </button>
                    </div>
                </div>
                <div className={'card'}>
                    <h5>info</h5>
                    <div className={'info-box puzzle-info-wrp'}>
                        <div className='puzzle-stats'>
                            <div className={'info' + (!puzzleId ? ' preloader' : '')}>
                                {puzzleId}
                                <div className='info-label'>ID</div>
                            </div>
                            <div className={'info' + (puzzleNr === undefined || puzzlesLength === 0 ? ' preloader' : '')}>
                                {puzzleNr !== undefined && puzzlesLength !== 0 && puzzleNr + 1 + ' / ' + puzzlesLength}
                                <div className='info-label'>Nr</div>
                            </div>
                            <div className={'info' + (!puzzleRating ? ' preloader' : '')}>
                                {puzzleRating}
                                <div className='info-label'>Rating</div>
                            </div>
                            <div className={'info' + (!puzzleMoves ? ' preloader' : '')}>
                                {puzzleMoves && Math.round(puzzleMoves.split(' ').length / 2)}
                                <div className="info-label">Moves</div>
                            </div>
                        </div>
                        <div className='puzzle-themes'>
                            {puzzle &&
                                puzzleThemes.split(' ').map((word: string, key) => {
                                    const puzzleTheme = puzzleThemeDefinitions[word.trim()];
                                    return puzzleTheme && <div className={"tag" + (activePuzzleThemeFilters?.includes(word.trim()) ? ' active' : '')} key={key} data-balloon-length="large" aria-label={puzzleTheme?.description} data-balloon-pos={'up' + (key <= 1 || key > 4 ? '-left' : (key >= 3 ? '-right' : ''))}>{puzzleTheme?.theme}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {modalToOpen && modalToOpen?.modal && !modalToOpen?.awaiting && (modalToOpen.modal)}
        </div>
    );
};

export default Puzzles;
