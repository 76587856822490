import React from "react";
import "./PrivacyPolicyTxt.scss";


const PrivacyPolicyTxt = () => {
    return <div className={'privacy-policy-txt'}>
        This Privacy Policy describes how the personal information you might
        provide is handled by Chessdose("Company," "we," "us," or "our"), when
        you download and use our mobile application. If you have any questions or
        concerns about this privacy policy or our practices with regards to your
        personal information, please contact us at <a href="mailto:info.chessdose@gmail.com">info.chessdose@gmail.com</a>
        <br /><br />
        <h3>Personal Information we collect</h3>
        When you visit the Site, we automatically collect certain information about your device,
        including information about your web browser, IP address, time zone, and some of the cookies
        that are installed on your device. Additionally, as you browse the Site, we collect
        information about the individual web pages that you view, what websites or search terms
        referred you to the Site, and information about how you interact with the Site.
        We refer to this automatically-collected information as “Device Information.”
        We collect Device Information using the following technologies:
        <ul>
            <li>
                <strong>Cookies</strong> are data files that are placed on your device or computer
                and often include an anonymous unique identifier. For more information about cookies,
                and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.
            </li>
            <li><strong>Log files</strong> track actions occurring on the Site, and collect data including your IP address,
                browser type, Internet service provider, referring/exit pages, and date/time stamps.
            </li>
            <li>
                <strong>Web beacons, tags and pixels</strong> are electronic files used to record information about how you browse the Site
                Chessdose uses Google Analytics to help us understand how our customers use the app. You can read more about
                how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy">https://www.google.com/intl/en/policies/privacy</a>.
                You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
            </li>
        </ul>
        <br /><br />
        <h3>We don’t collect or use Personal Information</h3>
        We don’t collect any personal information at all. Only information that we collect is generally to improve and optimize
        the user experience (for example to keep track of the state of chess games, users level), and its stored on the individual
        devices trough javascript/localStorage. localStorage is similar to sessionStorage, except that while localStorage data
        has no expiration time.
        <br /><br />
        <h3>We do not track</h3>
        Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
        <br /><br />
        <h3>Your rights</h3>
        If you are a European resident, you have the right to ask for any personal information we might hold about you and to ask
        that your personal information be corrected, updated, or deleted. If you would like to exercise this right,
        please contact us through <a href="mailto:info.chessdose@gmail.com">info.chessdose@gmail.com</a>.
        <br /><br />
        <h3>Minors</h3>
        The Site is not intended for individuals under the age of 3.
        <br /><br />
        <h3>Changes</h3>
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or
        for other operational, legal or regulatory reasons.
        <br /><br />
        <h3>Contact us</h3>
        For more information about our privacy practices, if you have questions, or if you would like to make a complaint,
        please contact us by e-mail at <a href="mailto:info.chessdose@gmail.com">info.chessdose@gmail.com</a> or by mail using the details provided below: <br />
        Manøgade 13, 2th, København Ø, 2100, Denmark.
        <br /><br />
        <strong>Last updated September 12, 2022</strong>
        <br /><br />
    </div>
};

export default PrivacyPolicyTxt;
