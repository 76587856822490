import React, { useCallback} from "react";
import "./PlayerPanel.scss";
import AIPlayer from "../../types/AIPlayerType"; 
import HumanPlayerType from "../../types/HumanPlayerType";
import AnalyzeStatsType from "../../types/AnalyzeStatsType"; 
import GameType from "../../types/GameType"; 

import Piece from "../Piece/Piece"; 
import PlayerProfile from "../PlayerProfile/PlayerProfile"; 
 
type PlayerPanelProps = { 
  gameType?:GameType,
  AIPlayer?: AIPlayer,
  humanPlayer?: HumanPlayerType,
  fen?: string,
  analyzerStats?:AnalyzeStatsType,
  aiThinkAnimation?:boolean,
  handleEditProfile?:() => void, 
  handleRunOutOfTime:(playerId: string) => void
};

const pieces: { [piece: string]: { value: number, amount: number } } = {
  p: { value: 1, amount: 8 },
  n: { value: 3, amount: 2 },
  b: { value: 3, amount: 2 },
  r: { value: 5, amount: 2 },
  q: { value: 9, amount: 1 },
  k: { value: 0, amount: 1 }
}
let points: { w: { pieces: { type: string, amount: number }[], points: number }, b: { pieces: { type: string, amount: number }[], points: number } } = {
  w: { pieces: [], points: 0 },
  b: { pieces: [], points: 0 }
};

let oldWidth: number = 50;

const PlayerPanel = (p: PlayerPanelProps) => {
  

  const handleRunOutOfTime = (playerId: string) => {
    if(p.handleRunOutOfTime){ 
      p.handleRunOutOfTime(playerId)
    } 
  } 

  const getPlayerColor = useCallback(() => { 
    return !p.AIPlayer || p.AIPlayer?.color === 'b' ? 'w' : 'b';
  }, [p.AIPlayer]);

  if (p.fen) {
    // Calculate points on each side  
    let whitePieces = p.fen.split(' ')[0].replace(/[^A-Z]/g, '');
    let blackPieces = p.fen.split(' ')[0].replace(/[^a-z]/g, '');
    let pieceTypes = Object.keys(pieces);
    points = {
      w: { pieces: [], points: 0 },
      b: { pieces: [], points: 0 }
    };
    for (let i = 0; i < pieceTypes.length; i++) {
      let p = pieceTypes[i];
      if (p) {
        let whiteAmount = pieces[p].amount - (blackPieces.match(new RegExp(p, 'g')) || []).length;
        points['w']['pieces'].push({ type: p, amount: whiteAmount });
        points['w']['points'] += whiteAmount * pieces[p].value;
        let blackAmount = pieces[p].amount - (whitePieces.match(new RegExp(p.toUpperCase(), 'g')) || []).length;
        points['b']['pieces'].push({ type: p, amount: blackAmount });
        points['b']['points'] += blackAmount * pieces[p].value;
      }
    }
  }
 
  let humanPlayer = {
    avatarSvg: p.humanPlayer?.avatarSvg,
    title: p.humanPlayer?.title,
    elo: p.humanPlayer?.elo,
    ai:false
  }

  let aiPlayer = {
    avatarSvg: p.AIPlayer?.avatarSvg,
    title: p.AIPlayer?.title,
    elo: p.AIPlayer?.elo,
    ai:true
  }

  let playerBlack = getPlayerColor() === 'b' ? humanPlayer : aiPlayer;
  let playerWhite = getPlayerColor() === 'b' ? aiPlayer : humanPlayer;

  let width: number = Number(p.analyzerStats?.cpScoreAfter ? ( getPlayerColor() === 'w' ? ( (4000 + (~p.analyzerStats.cpScoreAfter-1)) / 8000 * 100) : (100 - (4000 + (~p.analyzerStats.cpScoreAfter-1)) / 8000 * 100) ) : oldWidth);
  oldWidth = width;
 
  const blackWinningPctBarStyle = { 
    width: width.toString() + '%',
  };

  return <div className={'player-panel'}>  
    <div className={"winning-bar"}>{getPlayerColor() === 'w' && p.analyzerStats?.cpScore && p.analyzerStats?.cpScore !== 0 ? (p.analyzerStats?.cpScore / 1000).toFixed(2) : ''}
      <div className="black-winning-pct-bar" style={blackWinningPctBarStyle}>
        {getPlayerColor() === 'b' && p.analyzerStats?.cpScore && p.analyzerStats?.cpScore !== 0 ? (p.analyzerStats?.cpScore / 1000).toFixed(2) : ''}
      </div>
    </div> 
    {p.humanPlayer && 
      <div className={'player-white'}> 
          <PlayerProfile
              avatarSvg={playerWhite?.avatarSvg ? playerWhite?.avatarSvg : ''}
              title={playerWhite.title+' ('+playerWhite.elo+')'}   
              playerColor={'w'}
              thinking={(p.aiThinkAnimation && p.fen?.includes('w') && p.AIPlayer?.color === 'w') || (p.aiThinkAnimation && p.timedGameRunning && p.fen?.includes('w') && (p.gameType?.time ? true : false))}
              handleEditProfile={ p.humanPlayer?.color === 'w' && p.handleEditProfile ? () => {p.handleEditProfile && p.handleEditProfile()} : undefined }
              gameType={p.gameType}
              hasTurn={p.fen?.includes('w')} 
              handleRunOutOfTime={handleRunOutOfTime}   
          />
          
          {points && points['w'] && points['w']['pieces'] &&
            <div className={'pieces'}>
              {points['w']['pieces'].map((val, index) => {
                let pieces = [];
                for (let i = 0; i < (val.amount > 3 ? 3 : val.amount); i++) { 
                  pieces.push(<Piece key={'bt_' + i} type={val.type} className={'inline'} />)
                }
                return pieces.length ? <div className="pieces-group" key={'btg_' + index}>{pieces}</div> : [];
              })}  
              <div className={'piece-advantage'}>
                  { points['w']['points'] > points['b']['points'] ? points['w']['points'] - points['b']['points'] + ' +' : '' }
              </div> 
            </div>
            } 
      </div>
    }
     
    {aiPlayer && 
      <div className={'player-black'}>  
        <PlayerProfile
            avatarSvg={playerBlack?.avatarSvg ? playerBlack?.avatarSvg : ''} 
            title={playerBlack.title+' ('+playerBlack.elo+')'}  
            playerColor={'b'}
            thinking={(p.aiThinkAnimation && !p.fen?.includes('w') && p.AIPlayer?.color === 'b') || (p.aiThinkAnimation && p.timedGameRunning && !p.fen?.includes('w') && (p.gameType?.time ? true : false))}
            handleEditProfile={ p.humanPlayer?.color === 'b' && p.handleEditProfile ? () => {p.handleEditProfile && p.handleEditProfile()} : undefined }
            gameType={p.gameType}
            hasTurn={!p.fen?.includes('w')} 
            handleRunOutOfTime={p.handleRunOutOfTime} 
        />  
        {points && points['b'] && points['b']['pieces'] &&
          <div className={'pieces'}> 
            {points['b']['pieces'].map((val, index) => {
              let pieces = [];
              for (let i = 0; i < (val.amount > 3 ? 3 : val.amount); i++) {
                pieces.push(<Piece key={'bt_' + i} type={val.type.toUpperCase()} className={'inline'} />)
              }
              return pieces.length ? <div className="pieces-group" key={'btg_' + index}>{pieces}</div> : [];
            })} 
            <div className={'piece-advantage'}> 
              { points['b']['points'] > points['w']['points'] ? '+ ' + (points['b']['points'] - points['w']['points'])  :	'' }
            </div>  
          </div>} 
      </div>
    }
  </div>
};

export default PlayerPanel;