import React from "react";
import "./PrivacyPolicy.scss";
import PrivacyPolicyTxt from "../../components/PrivacyPolicy/PrivatePolicyTxt";

type PrivacyPolicyProps = {

};

const PrivacyPolicy = (p: PrivacyPolicyProps) => {
  return <div className={'privacy-policy'}>
    <div className="card dyn-padding">
      <h1>Private Policy - Chessdose</h1>
      <PrivacyPolicyTxt />
    </div>
  </div>
};

export default PrivacyPolicy;