import React, { lazy, Suspense, useState, useEffect, useCallback, useContext, RefObject, createRef } from "react";
import "./Play.scss";
/* Context */
import { UserSettingsContext } from "../../contexts/UserSettingsContextManagement";
import { TimedGameStateContext } from '../../contexts/TimedGameContextManagement'

/* Components */
import ChessBoard from "../../components/ChessBoard/ChessBoard";
import PlayerPanel from "../../components/PlayerPanel/PlayerPanel";
import Modal from "../../components/_atoms/Modal/Modal";
/* Types */
import AnalyzeStatsType from "../../types/AnalyzeStatsType";
import EcoType from "../../types/EcoType";
import GameType from "../../types/GameType";
import AIPlayerType from "../../types/AIPlayerType";
import HumanPlayerType from "../../types/HumanPlayerType";
import NotationType from "../../types/NotationType";
/* Helpers */
import { getSceenSizeBreakPoint } from "../../helpers/screenSizeHelper";
import { createAdaptivePlayer } from "../../helpers/aiPlayerHelper";
import { toJson } from "../../helpers/stringHelper";
import { secondsToTime } from "../../helpers/timeHelper";
import { setLocalStorageVariable, deleteLocalStorageVariable } from "../../helpers/localeStorageHelper";
import { categorizeMove, findEcoOpening, figurineToAlgebraicNotation } from "../../helpers/moveHelper";
import TimedGameStateType from "../../types/TimedGameStateType";

const Chess = require("chess.js");
const chess = new Chess();
const moveAnalyzerChess = new Chess();

/* LazyLoaded Components */
// https://gist.github.com/aliostad/f4470274f39d29b788c1b09519e67372
// https://github.com/nmrugg/stockfish.js/issues/11
//stockFistUrl = new URL('./stockfish-11/stockfish.js', import.meta.url);
const stockfishUrl = '/stockfish-11/stockfish.js';
const ai: Worker = new Worker(stockfishUrl, { type: 'module' });
const moveAnalyzerEngine: Worker = new Worker(stockfishUrl, { type: 'module' });
const cpScoreEngine: Worker = new Worker(stockfishUrl, { type: 'module' });
const moveHintEngine: Worker = new Worker(stockfishUrl, { type: 'module' });

const MoveList = lazy(() => import("../../components/MoveList/MoveList"));
const MoveAnalyzer = lazy(() => import("../../components/MoveAnalyzer/MoveAnalyzer"));
const Editprofile = lazy(() => import("../../components/EditProfile/EditProfile"));

/* LazyLoaded Modal Components */
const AIGameSetup = lazy(() => import("../../components/AIGameSetup/AIGameSetup"));
const GameOver = lazy(() => import("../../components/GameOver/GameOver"));

//deleteAllLocalStorageVariables(['lastAdaptiveGamePlayed']); 
//console.log(getAllStorage())
//https://freesound.org/ clausnymann / Nummer12
const winSound = new Audio(require("../../assets/sounds/win.mp3").default);
const lostSound = new Audio(require("../../assets/sounds/lost.mp3").default);
const eventSound = new Audio(require("../../assets/sounds/event.mp3").default);
// startFen : "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
// AI promotion : rnbqk3/ppp1p3/8/8/8/3P4/PPP4p/RNBQK3 b KQkq - 0 1
// White promotion : 
// AI checkMate: 1nb1kbnr/pppppppp/8/8/1rq5/K7/3PPPPP/5BNR b KQkq - 0 1
// Player checkMate: 8/8/8/8/8/8/QQ5Q/k1K5/ w KQkq - 0 1
//const startFenPos = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
const startFenPos = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

let tmpAnalyzerStats: AnalyzeStatsType = undefined;
let tmpMoveHint: { fen: string, from?: string; to?: string } = { fen: startFenPos };
let setTimeoutHandleShowMoveHint: ReturnType<typeof setTimeout>;
let setTimeoutMovetimeDelayId: ReturnType<typeof setTimeout>;
let setTimeoutAnalyzeId: ReturnType<typeof setTimeout>;

//gs.moveAnalyzerEngine.postMessage("setoption name UCI_AnalyseMode value 1");
moveAnalyzerEngine.postMessage("setoption name MultiPV value 10");
//gs.cpScoreEngine.postMessage("setoption name MultiPV value 1");

const Play = () => {

    const { setTimedGameState, timedGameState } = useContext(TimedGameStateContext)
    const { userSettings } = useContext(UserSettingsContext)

    const lsPgn = localStorage.getItem('pgn');
    const lsNotations = toJson(localStorage.getItem('notations'));
    const lsAIPlayer = toJson(localStorage.getItem('AIPlayer'));
    const lsGameType = toJson(localStorage.getItem('gameType'));
    const lsHumanPlayer = toJson(localStorage.getItem('humanPlayer'));
    const lsAnalyzerStats = toJson(localStorage.getItem('analyzerStats'));
    const lsEco = toJson(localStorage.getItem('eco'));
    const lsLastAdaptiveGamePlayed = localStorage.getItem('lastAdaptiveGamePlayed');

    const [fen, setFen] = useState<string>(lsNotations && lsNotations[lsNotations.length - 1].fen !== undefined ? lsNotations[lsNotations.length - 1].fen : startFenPos);

    const [pgn, setPgn] = useState<string>(lsPgn ? lsPgn : '');

    const [notations, setNotations] = useState<NotationType[]>(lsNotations ? lsNotations : []);
    const [notationIndex, setNotationIndex] = useState<number | undefined>();
    const [notationScrollIndex, setNotationScrollIndex] = useState<number | undefined>();

    const [lastMove, setLastMove] = useState<{ from: string, to: string } | undefined>(lsNotations ? { from: lsNotations[lsNotations.length - 1]?.from, to: lsNotations[lsNotations.length - 1]?.to } : undefined);

    const [chessBoardIsFlipped, setChessBoardIsFlipped] = useState<boolean>(lsAIPlayer?.color === 'b' || !lsAIPlayer ? false : true);
    const [AIPlayer, setAIPlayer] = useState<AIPlayerType>(lsAIPlayer ? lsAIPlayer : createAdaptivePlayer(lsLastAdaptiveGamePlayed ? parseInt(lsLastAdaptiveGamePlayed) : 4));
    const [adaptiveLevel, setAdaptiveLevel] = useState<number>(lsLastAdaptiveGamePlayed ? parseInt(lsLastAdaptiveGamePlayed) : 4);

    const [gameType, setGameType] = useState<GameType>(lsGameType ? lsGameType : { type: 'Classic', label: 'No time limit' });
    const [humanPlayer, setHumanPlayer] = useState<HumanPlayerType>(lsHumanPlayer && lsHumanPlayer.elo ? lsHumanPlayer : { title: 'Guest', color: 'w', elo: 800 });

    const [analyzerStats, setAnalyzerStats] = useState<AnalyzeStatsType>(lsAnalyzerStats);
    const [moveHint, setMoveHint] = useState<{ from: string, to?: string, san?: string } | undefined>();
    const [eco, setEco] = useState<EcoType>(lsEco);
    const [aiCalculating, setAiCalculating] = useState<boolean>(false);

    const [aiMove, setAiMove] = useState<{ from: string; to: string; promotion?: string; showOnBoard?: boolean } | undefined>();
    const [modalToOpen, setModalToOpen] = useState<{ modal?: React.ReactNode, closable?: boolean, noOverlay?: true, awaiting?: boolean } | undefined>();

    const handleResetBoard = useCallback((newFen?: string) => {
        if (gameType?.time) {
            setTimedGameState({
                wPlayerTimeLeft: undefined,
                bPlayerTimeLeft: undefined,
                timerRunning: false
            })
        }
        setAiMove(undefined);
        setAiCalculating(false)
        deleteLocalStorageVariable(['fen', 'pgn', 'notations', 'lastMove', 'analyzerStats', 'eco'])
        setAnalyzerStats(undefined)
        setEco(undefined)
        setNotations([])
        setNotationIndex(undefined)
        setResetBoard({
            indicateCheckMove: true,
            activeSquare: true,
            legalMoves: true,
            squareDraggedOver: true,
            showModalPromotion: true,
            moveHint: true,
            lastMove: true,
            isFlipped: true,
        });
        chess.reset();
        if (newFen) {
            chess.load(newFen);
            setFen(newFen);
        } else {
            setFen(startFenPos);
        }
        setPgn(chess.pgn())
        setAiMove(undefined);
    }, [setTimedGameState, gameType]);

    const handleNewGame = useCallback((newAdaptiveLevel?: number, newHumanPlayer?: HumanPlayerType) => {
        handleResetBoard()

        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();

        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Play vs...'}
                isClossable={false}
                setModalToOpen={setModalToOpen}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => { modalChildref.current.refHandlePlay() }}>Play</button>}
            >
                <AIGameSetup
                    ref={modalChildref}
                    adaptiveLevel={newAdaptiveLevel ? newAdaptiveLevel : adaptiveLevel}
                    setAIPlayer={setAIPlayer}
                    setGameType={setGameType}
                    gameType={gameType}
                    humanPlayer={newHumanPlayer ? newHumanPlayer : humanPlayer}
                    setHumanPlayer={setHumanPlayer}
                    AIPlayer={AIPlayer}
                    setChessBoardIsFlipped={setChessBoardIsFlipped}
                    setModalToOpen={setModalToOpen}
                />
            </Modal></Suspense>
        })

    }, [gameType, AIPlayer, humanPlayer, adaptiveLevel, handleResetBoard]);


    const handleResumeGame = useCallback(() => {

        if (gameType?.time) {
            setTimedGameState({
                ...timedGameState,
                timerRunning: true
            })
        }
        setModalToOpen(undefined)
        setAiCalculating(false)
    }, [setTimedGameState, timedGameState, gameType])


    const startNewGame = useCallback(() => {

        if (notations.length > 0 || gameType?.time) {
            let wPlayerTimeLeft = undefined;
            let bPlayerTimeLeft = undefined;
            if (gameType?.time) {
                setTimedGameState({
                    ...timedGameState,
                    timerRunning: false
                })
                wPlayerTimeLeft = secondsToTime(timedGameState.wPlayerTimeLeft ? timedGameState.wPlayerTimeLeft : 0);
                bPlayerTimeLeft = secondsToTime(timedGameState.bPlayerTimeLeft ? timedGameState.bPlayerTimeLeft : 0);
            }

            setModalToOpen({
                modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                    header={'Sure?'}
                    isClossable={false}
                    setModalToOpen={setModalToOpen}
                    callToActionsBtns={
                        <>
                            <button className={'btn-1-xl'} onClick={() => handleResumeGame()}>Resume</button>
                            <button className={'btn-2-xl'} onClick={() => handleNewGame()}>New Game</button>
                        </>
                    }
                >
                    <div className="modal-content-inner">
                        <div className="modal-content-inner-txt">
                            <strong>Previous game({gameType?.label}) is uncompleted...</strong><br />
                            {gameType?.time && wPlayerTimeLeft && bPlayerTimeLeft && (parseInt(wPlayerTimeLeft) + parseInt(bPlayerTimeLeft) > 0) &&
                                <small>White has {wPlayerTimeLeft} and black has {bPlayerTimeLeft} time left.</small>
                            }
                        </div>
                    </div>
                </Modal></Suspense>
            })
        } else {
            handleNewGame();
        }
    }, [notations, gameType, timedGameState, handleNewGame, handleResumeGame, setTimedGameState]);


    useEffect(() => {
        clearTimeout(setTimeoutHandleShowMoveHint);
        clearTimeout(setTimeoutMovetimeDelayId);
        clearTimeout(setTimeoutAnalyzeId)
        ai.postMessage("quit");
        moveAnalyzerEngine.postMessage("quit");
        cpScoreEngine.postMessage("quit");
        moveHintEngine.postMessage("quit");
        tmpAnalyzerStats = undefined;
        // If there is a missmatch between the lsFen and lsNotations last fen, we have a problem.. 
        // Solution set fen to last element 
        // preserve history if reloaded etc.
        const lsPgn = localStorage.getItem('pgn');
        if (lsPgn) {
            chess.reset();
            chess.load_pgn(lsPgn);
        }
        //console.log('set Chess and useEffect resets timeout and quit workers')   

    }, []);


    const handleRunOutOfTime = useCallback((playerId: string) => {
        setTimedGameState({
            ...timedGameState,
            timerRunning: false
        })
        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                setModalToOpen={setModalToOpen}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => modalChildref.current.refHandleNewGame(ev)}>New Game</button>
                }
            >
                <GameOver
                    ref={modalChildref}
                    result={{ in: 'time_out', winningColor: playerId === 'w' ? 'b' : 'w' }}
                    AIPlayer={AIPlayer}
                    humanPlayer={humanPlayer}
                    setHumanPlayer={setHumanPlayer}
                    notations={notations}
                    handleNewGame={handleNewGame}
                    handleResumeGame={handleResumeGame}
                />
            </Modal></Suspense>
        })
    }, [AIPlayer, setTimedGameState, timedGameState, handleNewGame, handleResumeGame, humanPlayer, notations])


    useEffect(() => { setLocalStorageVariable('gameType', JSON.stringify(gameType)) }, [gameType]);
    useEffect(() => { setLocalStorageVariable('AIPlayer', JSON.stringify(AIPlayer)) }, [AIPlayer]);
    useEffect(() => { setLocalStorageVariable('humanPlayer', JSON.stringify(humanPlayer)) }, [humanPlayer]);
    useEffect(() => { setLocalStorageVariable('lastAdaptiveGamePlayed', JSON.stringify(adaptiveLevel)) }, [adaptiveLevel]);

    moveHintEngine.onmessage = (event) => {
        let res = event.data;
        let evenType = res.substr(0, res.indexOf(" "));
        if (evenType === "bestmove") {
            let match = res.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
            if (match && tmpMoveHint) {
                tmpMoveHint.from = match[1]
                tmpMoveHint.to = match[2]
            }
        }
    }

    useEffect(() => {
        setMoveHint(undefined)
        tmpMoveHint = { fen: fen };

        if (AIPlayer) {
            moveHintEngine.postMessage("quit");
            if (!chess.game_over() && chess.turn() !== AIPlayer.color) {
                moveHintEngine.postMessage("position fen " + fen);
                moveHintEngine.postMessage("go movetime 1100");
            }
        }
    }, [fen, AIPlayer]);

    const handleShowMoveHint = () => {
        if (!moveHint && !fen.includes(' ' + AIPlayer?.color + ' ')) {
            if (fen === tmpMoveHint.fen && tmpMoveHint?.from && tmpMoveHint?.to) {
                setMoveHint({ from: tmpMoveHint?.from, to: tmpMoveHint?.to })
            } else {
                if (setTimeoutHandleShowMoveHint) {
                    clearTimeout(setTimeoutHandleShowMoveHint);
                }
                setTimeoutHandleShowMoveHint = setTimeout(() => { handleShowMoveHint(); }, 300);
            }
        } else {
            setMoveHint(undefined)
        }
    };

    const handleEditProfile = () => {
        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Your profile'}
                isClossable={true}
                setModalToOpen={setModalToOpen}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => modalChildref.current.refHandleSaveHumanPlayer()}>Save</button>}
            >
                <Editprofile
                    ref={modalChildref}
                    humanPlayer={humanPlayer}
                    setHumanPlayer={setHumanPlayer}
                    setModalToOpen={setModalToOpen}
                />
            </Modal></Suspense>, closable: true
        })
    };

    const [resetBoard, setResetBoard] = useState<
        | {
            indicateCheckMove?: boolean;
            activeSquare?: boolean;
            legalMoves?: boolean;
            squareDraggedOver?: boolean;
            showModalPromotion?: boolean;
            moveHint?: boolean;
            lastMove?: boolean;
            isFlipped?: boolean;
        }
        | undefined
    >();

    moveAnalyzerEngine.onmessage = (event) => {
        let res = event.data;
        let evenType = res.substr(0, res.indexOf(" "));
        if (evenType === "info") {
            if (res.startsWith("info depth")) {
                let _depth = /(?:depth )([0-9]+)/.test(res) ? RegExp.$1 : "0";
                if (_depth && /^\d+$/.test(_depth)) {
                    if (tmpAnalyzerStats) {
                        tmpAnalyzerStats.depth = Number(_depth);
                        if (!tmpAnalyzerStats.cpScore) {
                            let _cpScore = /(?:cp )([0-9-]+)/.test(res) ? RegExp.$1 : undefined;
                            if (_cpScore) {
                                tmpAnalyzerStats.cpScore = Number(_cpScore);
                            }
                        }
                    }
                }
                //Principal Variation
                let _pv = / pv (.*) bmc/.test(res) ? RegExp.$1 : undefined;
                if (_pv && tmpAnalyzerStats) {
                    let analyzerPv = _pv.split(' ');
                    if (analyzerPv[0]) {
                        let move: { from: string; to: string, promotion?: string, san?: string, cp?: number, pv?: string[] } = {
                            from: analyzerPv[0][0] + analyzerPv[0][1],
                            to: analyzerPv[0][2] + analyzerPv[0][3]
                        };
                        if (analyzerPv[0][4]) {
                            move.promotion = analyzerPv[0][4]
                        }
                        let n = moveAnalyzerChess.move(move);
                        moveAnalyzerChess.undo()
                        if (n) {
                            move.san = n.san
                            let _score = / cp (.*) nodes/.test(res) ? RegExp.$1 : undefined;
                            if (_score) {
                                move.cp = parseInt(_score)
                                move.pv = analyzerPv;
                                if (tmpAnalyzerStats.cpMoves) {
                                    tmpAnalyzerStats.cpMoves[analyzerPv[0]] = move;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (evenType === "bestmove") {
            clearTimeout(setTimeoutAnalyzeId);

            let match = res.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
            let lastPlayerNotationKey = AIPlayer?.color ? notations.map(notation => notation.color).lastIndexOf((AIPlayer?.color === 'w' ? 'b' : 'w')) : undefined;

            if (match && tmpAnalyzerStats && lastPlayerNotationKey !== undefined && notations[lastPlayerNotationKey] && !notations[lastPlayerNotationKey].analyzeStats) {

                let bestMove: { from: string; to: string, promotion?: string, san?: string } = {
                    from: match[1],
                    to: match[2]
                };
                if (match[3] && match[3] !== null) {
                    bestMove.promotion = match[3];
                }
                let n = moveAnalyzerChess.move(bestMove);

                if (n) {
                    moveAnalyzerChess.undo();
                    bestMove.san = n.san
                    tmpAnalyzerStats.bestMove = bestMove;
                    const moveKey: string = bestMove.from + bestMove.to + (bestMove?.promotion ? bestMove?.promotion : '');
                    if (tmpAnalyzerStats.cpMoves && tmpAnalyzerStats.cpMoves[moveKey]) {
                        tmpAnalyzerStats.bestMove.pv = tmpAnalyzerStats.cpMoves[moveKey] ? figurineToAlgebraicNotation(tmpAnalyzerStats.cpMoves[moveKey].pv, tmpAnalyzerStats?.analyzed?.fen, tmpAnalyzerStats?.round) : undefined;
                        tmpAnalyzerStats.bestMove.cp = tmpAnalyzerStats.cpMoves[moveKey] ? tmpAnalyzerStats.cpMoves[moveKey].cp : (tmpAnalyzerStats?.cpScore && tmpAnalyzerStats?.cpScore !== undefined && tmpAnalyzerStats.cpScore > 0 ? tmpAnalyzerStats.cpScore : notations[notations.length - 2].analyzeStats?.bestMove?.cp);
                        tmpAnalyzerStats.moveType = categorizeMove(tmpAnalyzerStats, (notationIndex && notationIndex <= 3) || notations.length <= 3)
                    }
                    setLocalStorageVariable('analyzerStats', JSON.stringify({ ...tmpAnalyzerStats }))
                    let updatedNotations = [...notations];
                    updatedNotations[lastPlayerNotationKey].analyzeStats = tmpAnalyzerStats;
                    setAnalyzerStats({ ...tmpAnalyzerStats });
                    setNotations(updatedNotations);

                }
            }
        }
    };

    cpScoreEngine.onmessage = (event) => {
        let res = event.data;
        let evenType = res.substr(0, res.indexOf(" "));
        if (evenType === "info") {
            if (res.startsWith("info depth")) {
                //Principal Variation
                let _pv = / pv (.*) bmc/.test(res) ? RegExp.$1 : undefined;
                if (_pv && tmpAnalyzerStats) {
                    if (tmpAnalyzerStats) {
                        tmpAnalyzerStats.pv = _pv.split(' ');
                    }
                }
                let _cpScore = /(?:cp )([0-9-]+)/.test(res) ? RegExp.$1 : undefined;
                if (_cpScore) {
                    if (tmpAnalyzerStats) {
                        tmpAnalyzerStats.cpScoreAfter = Number(_cpScore);
                    }
                }
            }
        }
    }

    useEffect(() => {

        if (AIPlayer && notationIndex === undefined && !chess.game_over() && chess.turn() === AIPlayer.color && notations.length > 0 && notations[notations.length - 1] && !notations[notations.length - 1].analyzeStats) {

            let fenToAnalyse = notations[notations.length - 1].oldFen ? notations[notations.length - 1].oldFen : startFenPos;
            let sanToAnalyse = notations[notations.length - 1].san;
            if (fenToAnalyse) {
                tmpAnalyzerStats = {
                    round: Number(fenToAnalyse.substring(fenToAnalyse.lastIndexOf(' ') + 1)),
                    analyzed: { fen: fenToAnalyse, san: sanToAnalyse },
                    cpMoves: {}
                }
                setAnalyzerStats(tmpAnalyzerStats);
                setLocalStorageVariable('analyzerStats', JSON.stringify(tmpAnalyzerStats))
                //cpScoreEngine.postMessage("stop");
                cpScoreEngine.postMessage("position fen " + fenToAnalyse);
                cpScoreEngine.postMessage("go depth 15 searchmoves " + notations[notations.length - 1].from + notations[notations.length - 1].to);
                // console.log('start analyzing on fen')
                // gs.moveAnalyzerChess.postMessage("stop");
                moveAnalyzerChess.load(fenToAnalyse);
                moveAnalyzerEngine.postMessage("position fen " + fenToAnalyse);
                moveAnalyzerEngine.postMessage("go depth 15");
                setTimeoutAnalyzeId = setTimeout(() => {
                    cpScoreEngine.postMessage("stop");
                    setTimeoutAnalyzeId = setTimeout(() => {
                        moveAnalyzerEngine.postMessage("stop");
                    }, 100);
                }, 600);

            }
        }
    }, [fen, notations, setAnalyzerStats, notationIndex, AIPlayer]);


    useEffect(() => {
        if (AIPlayer) {
            let level = AIPlayer.level === 0 ? adaptiveLevel : AIPlayer.level;
            ai.postMessage('setoption name Skill Level value ' + (level > 1 ? Math.round(level) : 1));
            ai.postMessage('setoption name Skill Level Maximum Error value ' + Math.round(1000 - ((1000 / 20) * level)));
            ai.postMessage('setoption name Skill Level Probability value ' + Math.round(10 * level > 1 ? 10 * level : 1));
            ai.postMessage('setoption name Contempt value ' + Math.round(level * 1.5 > 0 ? level * 1.5 : 1));
        }
    }, [AIPlayer, adaptiveLevel]);

    ai.onmessage = (event) => {

        let res = event.data;
        let evenType = res.substr(0, res.indexOf(" "));
        if (evenType === "bestmove" && !modalToOpen) {

            let match = res.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
            if (match) {
                let move: { from: string; to: string; promotion?: string } = {
                    from: match[1],
                    to: match[2],
                }
                if (match[3] && match[3] !== null) {
                    move.promotion = match[3];
                }

                setAiMove(move);
                setAiCalculating(false)
            }
        }
    }



    useEffect(() => {
        if (((timedGameState.timerRunning === true && gameType?.time) || !gameType?.time) &&
            !aiMove &&
            AIPlayer &&
            notationIndex === undefined &&
            (fen?.includes('w') ? 'w' : 'b') === AIPlayer?.color &&
            !aiCalculating
        ) {
            setAiCalculating(true)
            clearTimeout(setTimeoutMovetimeDelayId);
            const rndInt = Math.floor(Math.random() * (10 - 2 + 1) + 2)
            const timeDelay = gameType?.time ? ((rndInt * (310 - (AIPlayer.elo * .1))) + 3100 - AIPlayer.elo) + (parseInt(gameType.time) * 100) : (rndInt * 10) + 900
            setTimeoutMovetimeDelayId = setTimeout(() => {
                //console.log("ai.postMessage: " + fen)
                ai.postMessage("position fen " + fen);
                ai.postMessage("go movetime " + (gameType?.time ? (25 * parseInt(gameType.time) + (310 - (AIPlayer.elo * .1))) : AIPlayer.level * 150));
            }, timeDelay); //
        }

    }, [fen, AIPlayer, notationIndex, aiMove, modalToOpen, timedGameState, aiCalculating, setAiCalculating, gameType]);

    const handleResign = () => {
        if (gameType?.time) {
            setTimedGameState({
                ...timedGameState,
                timerRunning: false
            })
        }
        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                setModalToOpen={setModalToOpen}
                callToActionsBtns={
                    <>
                        <button className={'btn-1-xl'} onClick={(ev) => modalChildref.current.refHandleResume(ev)}>Resume</button>
                        <button className={'btn-2-xl'} onClick={(ev) => modalChildref.current.refHandleNewGame(ev)}>New Game</button>
                    </>
                }
            >
                <GameOver
                    ref={modalChildref}
                    result={{ in: 'resign', winningColor: AIPlayer?.color }}
                    AIPlayer={AIPlayer}
                    humanPlayer={humanPlayer}
                    setHumanPlayer={setHumanPlayer}
                    notations={notations}
                    handleNewGame={handleNewGame}
                    handleResumeGame={handleResumeGame}
                />
            </Modal></Suspense>
        })
    };

    useEffect(() => {
        if (!modalToOpen && !notationIndex) {

            if (chess.game_over()) {
                if (AIPlayer && chess.in_checkmate()) {
                    let win = AIPlayer.color !== (chess.turn() === 'b' ? 'w' : 'b');
                    if (userSettings.enableSounds) {
                        let eventSoundPromise = win ? winSound.play() : lostSound.play();
                        eventSoundPromise.catch(error => { });
                    }
                }
                setModalToOpen({ awaiting: true });
                setTimeout(() => {

                    let result = chess.in_checkmate() && 'checkmate';
                    result = chess.in_draw() ? 'draw' : result;
                    result = chess.in_stalemate() ? 'stalemate' : result;
                    result = chess.insufficient_material() ? 'insufficient_material' : result;
                    result = chess.in_threefold_repetition() ? 'threefold_repetition' : result;
                    result = !result ? 'resign' : result;

                    let newAdaptiveLevel: number | undefined;
                    if (AIPlayer && AIPlayer.adaptive && chess.in_checkmate()) {
                        let win = AIPlayer.color !== (chess.turn() === 'b' ? 'w' : 'b');
                        newAdaptiveLevel = win ? adaptiveLevel + 3 : ((adaptiveLevel - 1.5) > 0 ? adaptiveLevel - 1.5 : 4);
                        setAdaptiveLevel(newAdaptiveLevel)
                        setAIPlayer(createAdaptivePlayer(newAdaptiveLevel))
                    }

                    const modalChildref: RefObject<any> = createRef<HTMLDivElement>();

                    setModalToOpen({
                        modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                            setModalToOpen={setModalToOpen}
                            callToActionsBtns={<button className={'btn-2-xl'} onClick={(ev) => modalChildref.current.refHandleNewGame(ev)}>New Game</button>}
                        >
                            <GameOver
                                ref={modalChildref}
                                result={{ in: result, winningColor: result === 'checkmate' ? (chess.turn() === 'b' ? 'w' : 'b') : undefined }}
                                AIPlayer={AIPlayer}
                                humanPlayer={humanPlayer}
                                setHumanPlayer={setHumanPlayer}
                                notations={notations}
                                handleNewGame={handleNewGame}
                                newAdaptiveLevel={newAdaptiveLevel ? newAdaptiveLevel : undefined}
                                handleResumeGame={handleResumeGame}
                            />
                        </Modal></Suspense>
                    })

                }, 2500);

            } else if (!chess.game_over() && gameType?.time) {

                const { timerRunning, wPlayerTimeLeft, bPlayerTimeLeft } = timedGameState;

                if (!timerRunning && wPlayerTimeLeft && wPlayerTimeLeft > 0 && bPlayerTimeLeft && bPlayerTimeLeft > 0) {
                    const wPlayerTimeLeft = secondsToTime(timedGameState.wPlayerTimeLeft ? timedGameState.wPlayerTimeLeft : 0);
                    const bPlayerTimeLeft = secondsToTime(timedGameState.bPlayerTimeLeft ? timedGameState.bPlayerTimeLeft : 0);

                    setModalToOpen({
                        modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                            header={'Resume?'}
                            isClossable={false}
                            setModalToOpen={setModalToOpen}
                            callToActionsBtns={
                                <>
                                    <button className={'btn-1-xl'} onClick={() => handleNewGame()}>New Game</button>
                                    <button className={'btn-2-xl'} onClick={() => handleResumeGame()}>Resume</button>
                                </>
                            }
                        >
                            <div className="modal-content-inner">
                                <div className="modal-content-inner-txt">
                                    <strong>Previous game({gameType?.label}) is uncompleted...</strong><br />
                                    {wPlayerTimeLeft && bPlayerTimeLeft &&
                                        <small>White has {wPlayerTimeLeft} and black has {bPlayerTimeLeft} time left.</small>
                                    }
                                </div>
                            </div>
                        </Modal></Suspense>
                    })
                } else if (wPlayerTimeLeft === 0 || bPlayerTimeLeft === 0) {
                    handleRunOutOfTime(timedGameState.bPlayerTimeLeft === 0 ? 'b' : 'w')
                }
            } else if (chess.in_check()) {
                if (userSettings.enableSounds) {
                    setTimeout(() => { let eventSoundPromise = eventSound.play(); eventSoundPromise.catch(error => { console.log(error); }) }, 170);
                }
            }
        }
    }, [fen, gameType, handleRunOutOfTime, modalToOpen, timedGameState, AIPlayer, notationIndex, handleNewGame, notations, humanPlayer, userSettings, adaptiveLevel, handleResumeGame, handleResetBoard]);

    const addToNotation = (n: NotationType) => {
        if (
            (notationIndex === 0 && notations) ||
            (notationIndex && notations && notationIndex < notations.length)
        ) {
            console.log(notationIndex);
            notations.length = notationIndex;
        }
        let move: NotationType = {
            color: n.color,
            from: n.from,
            to: n.to,
            flags: n.flags,
            piece: n.piece,
            san: n.san,
            oldFen: fen,
            fen: n.fen
        }
        let updatedTimeGameState: TimedGameStateType = {
            ...timedGameState,
            timerRunning: true
        }
        if (gameType?.time) {
            // Add time to move if gain
            const gameTypeParts = gameType?.time?.split('|');
            if (gameTypeParts.length > 1) {
                const playerTimeLeft = n.color === 'w' ? timedGameState.wPlayerTimeLeft : timedGameState.bPlayerTimeLeft
                if (playerTimeLeft) {
                    if (n.color === 'w') {
                        updatedTimeGameState.wPlayerTimeLeft = playerTimeLeft + parseInt(gameType.time.split('|')[1])
                    } else {
                        updatedTimeGameState.bPlayerTimeLeft = playerTimeLeft + parseInt(gameType.time.split('|')[1])
                    }
                }
            }
            move.playerTimesLeftAtMove = { wPlayerTimeLeft: timedGameState.wPlayerTimeLeft, bPlayerTimeLeft: timedGameState.bPlayerTimeLeft }
        }

        let updatedNotations: NotationType[] = [
            ...notations,
            move
        ];
        setTimedGameState(updatedTimeGameState);
        setLocalStorageVariable('notations', JSON.stringify(updatedNotations))
        setPgn(chess.pgn())
        setLocalStorageVariable('pgn', chess.pgn())
        setNotations(updatedNotations);
        setNotationIndex(undefined)
        setNotationScrollIndex(undefined)
        setFen(n.fen);
        setLastMove({ from: n.from, to: n.to });
        findEcoOpening(updatedNotations, setEco);
    };

    const handleShowNotation = (index: number, scrollToIndex?: boolean) => {
        // Only allow this if AI has finished mov 
        if (AIPlayer?.color === (notations[notations.length - 1].fen?.includes('w') ? 'w' : 'b')) {
            return false;
        }
        ai.postMessage("quit");
        moveAnalyzerEngine.postMessage("quit");
        cpScoreEngine.postMessage("quit");
        moveHintEngine.postMessage("quit");
        tmpAnalyzerStats = undefined;
        clearTimeout(setTimeoutHandleShowMoveHint);
        clearTimeout(setTimeoutMovetimeDelayId);
        clearTimeout(setTimeoutAnalyzeId)
        let notation = notations[index];
        if (notation) {
            if (gameType?.time) {
                setTimedGameState({
                    wPlayerTimeLeft: notation.playerTimesLeftAtMove?.wPlayerTimeLeft,
                    bPlayerTimeLeft: notation.playerTimesLeftAtMove?.bPlayerTimeLeft,
                    timerRunning: false
                })
            }
            setNotationIndex(index + 1);
            if (scrollToIndex) {
                setNotationScrollIndex(index + 1);
            }
            setLastMove({ from: notation.from, to: notation.to });
            setFen(notation.fen);
            if (notation.analyzeStats) {
                setAnalyzerStats(notation.analyzeStats);
            } else if (notations[index - 1] && notations[index - 1].analyzeStats) {
                setAnalyzerStats(notations[index - 1].analyzeStats);
            } else if (notations[index + 1] && notations[index + 1].analyzeStats) {
                setAnalyzerStats(notations[index + 1].analyzeStats);
            } else {
                setAnalyzerStats(undefined);
            }

            let updatedNotations = [...notations];
            updatedNotations = updatedNotations.slice(0, index + 1)

            chess.reset();
            //chess.load_pgn(pgn) 

            for (let i = 0; i < updatedNotations.length; i++) {
                chess.move(notations[i]);
            }
            setPgn(chess.pgn())
            findEcoOpening(updatedNotations, setEco);
        }
        setResetBoard({
            activeSquare: true,
            squareDraggedOver: true,
            legalMoves: true,
            showModalPromotion: true,
            indicateCheckMove: true
        });
    }

    const backInNotation = (toStart?: boolean) => {
        let index = toStart ? 0 : notationIndex ? notationIndex - 2 : notations.length - 2;
        if (index >= 0) {
            handleShowNotation(index, true)
        }
    }

    const forwardInNotation = (toEnd?: undefined) => {
        let index = toEnd ? notations.length - 1 : (notationIndex !== undefined && notationIndex < notations.length) ? notationIndex : notations.length - 1;
        if (index <= notations.length - 1) {
            handleShowNotation(index, true)
        }
    }
    const pagePlayClass = [
        'page-play'
    ].join(' ');

    const pagePlayChessBoardToolsClass = [
        'btn-group',
        'chessboard-tools',
        ...(!userSettings.showHintButton ? ['no-hint-btn'] : []),
    ].join(' ');

    const showToolTips = ['lg', 'xl', 'xxl'].includes(getSceenSizeBreakPoint());
    const datBalloonPos = showToolTips ? 'up' : null;
    //  <button tabIndex={4} aria-label="New game" data-balloon-pos={datBalloonPos} onClick={ (ev) => { handleNewGame() }} className={"btn-1-lg icon-plus"}></button>

    return (
        <div className={pagePlayClass}>
            <div className={'chessboard-wrp'} role={'main'}>
                <ChessBoard
                    fen={fen}
                    setFen={setFen}
                    lastMove={lastMove}
                    setLastMove={setLastMove}
                    chessBoardIsFlipped={chessBoardIsFlipped}
                    //toggleChessBoardIsFlipped={toggleChessBoardIsFlipped}
                    boardIsModifiable={true}
                    showLegalMoves={userSettings.showLegalMoves}
                    onlyAllowLegalMoves={true}
                    showLastMove={userSettings.showLastMove}
                    addToNotation={addToNotation}
                    AIPlayer={AIPlayer}
                    humanPlayer={humanPlayer}
                    aiMove={aiMove}
                    setAiMove={setAiMove}
                    isNotationshidden={!userSettings.showBoardNotations}
                    isNotationsOutside={userSettings.showNotationOutsideOfBoard}
                    resetBoard={resetBoard}
                    setResetBoard={setResetBoard}
                    notationIndex={notationIndex}
                    setNotationIndex={setNotationIndex}
                    moveHint={moveHint}
                    setMoveHint={setMoveHint}
                    chess={chess}
                    enableSounds={userSettings.enableSounds}
                />
                <div className={pagePlayChessBoardToolsClass}>
                    <button tabIndex={0} aria-label={'Previous move'} data-balloon-pos={datBalloonPos} data-balloon-length="medium" onClick={(ev) => { backInNotation() }} className={"btn-1-lg icon-chevron-left first"}></button>
                    <button tabIndex={1} aria-label={'Next move'} data-balloon-pos={datBalloonPos} onClick={(ev) => { forwardInNotation() }} className={"btn-1-lg icon-chevron-right"}></button>
                    {userSettings.showHintButton && <button tabIndex={2} aria-label="Get a Hint" data-balloon-pos={datBalloonPos} onClick={(ev) => { handleShowMoveHint() }} className={"btn-1-lg icon-bulp"}></button>}
                    <button tabIndex={3} aria-label="Resign" data-balloon-pos={datBalloonPos} onClick={(ev) => { handleResign() }} className={"btn-1-lg icon-flag"}></button>
                    <button tabIndex={4} aria-label="New game" data-balloon-pos={datBalloonPos} onClick={(ev) => { startNewGame() }} className={"btn-1-lg icon-plus"}></button>
                </div>
            </div>
            <div className='info-wrp' role='main'>
                <PlayerPanel
                    fen={fen}
                    AIPlayer={AIPlayer}
                    humanPlayer={humanPlayer}
                    analyzerStats={analyzerStats}
                    handleEditProfile={handleEditProfile}
                    aiThinkAnimation={!notationIndex || (notationIndex && notationIndex === notations.length) ? true : false}
                    gameType={gameType}
                    handleRunOutOfTime={handleRunOutOfTime}
                />
                {!notations.length && (
                    <div className="card card-bg-2 choose-btn-wrp" role={'complementary'}>
                        <button className="btn-2-xl" onClick={() => handleNewGame()}> Choose </button>
                    </div>
                )}
                {notations.length > 0 && analyzerStats?.analyzed?.san && userSettings.showEvalutation && (
                    <Suspense fallback={<div className={'move-analyser-loading'} />}>
                        <MoveAnalyzer
                            className="card"
                            analyzerStats={analyzerStats}
                            eco={eco}
                            showBookMove={(notationIndex && notationIndex <= 3) || notations.length <= 3}
                        />
                    </Suspense>
                )}
                {notations.length > 0 && (
                    <Suspense fallback={<div className={'move-list-loading'} />}>
                        <MoveList
                            AIPlayer={AIPlayer}
                            humanPlayer={humanPlayer}
                            className="card"
                            notations={notations}
                            activeIndex={notationIndex}
                            indexToScrollTo={notationScrollIndex}
                            showNotation={handleShowNotation}
                            eco={eco}
                            pgn={pgn}
                            gameType={gameType}
                        />
                    </Suspense>
                )}
            </div>
            {modalToOpen && modalToOpen?.modal && !modalToOpen?.awaiting && (modalToOpen.modal)}
        </div>
    )
};

export default Play;