import React, { useState, useEffect } from "react";

import "./RangeSlider.scss";

type RangeSliderProps = { 
    classNames?: string,
    min: number,
    max: number,
    value?: number,
    step: number,
    tabIndex?: number,
    onChange: (ev: React.FormEvent<HTMLInputElement>) => void,
    syncOnValueChange?: boolean,
    labels?: {min: string, max: string} 
};

const RangeSlider = (p: RangeSliderProps) => {

    const [sliderVal, setSliderVal] = useState<number>(p.value ? p.value : 0);

    const changeCallback = (ev: React.FormEvent<HTMLInputElement>) => { 
        p.onChange(ev)
        setSliderVal(parseInt(ev.currentTarget.value));
    }
    
    useEffect(() => { p.syncOnValueChange && setSliderVal(p.value ? p.value : 0) }, [p.value, p.syncOnValueChange]); 
    
    const input = <input 
            type="range"
            value={sliderVal} 
            className={'slider' + (p.classNames ? p.classNames : '')}
            min={p.min}
            max={p.max}
            step={p.step}
            tabIndex={p.tabIndex && p.tabIndex }
            onChange={changeCallback}
        />  

      return (p.labels ?  
          <div className={'range-slider-wrp'}> 
            {input}
            <label className={'range-label-min'}>{p.labels?.min}</label>
            <label className={'range-label-max'}>{p.labels?.max}</label> 
          </div>
          :
          input
        );

      
};

export default RangeSlider;