import emailjs from 'emailjs-com';

type emailSettings = {
    fromEmail?: string, 
    fromName?:  string, 
    toEmail?:  string, 
    toName?:  string, 
    textPart?:  string, 
    subject?:  string, 
    htmlPart:  string, 
    customID?:  string, 
}

export function sendMail(emailSettings: emailSettings) {
 
    const templateParams: emailSettings = {
        fromEmail : 'info.chessdose@gmail.com', 
        fromName : 'Chessdose',
        toEmail : 'info.chessdose@gmail.com',  
        toName : 'Chessdose',  
        textPart: 'Feedback',
        subject: 'Feedback', 
        customID: 'Chessdose',
        ...emailSettings
    }; 

    if(templateParams.htmlPart){ 
        emailjs.send('service_0dtp2dc', 'template_qj4vt0z', templateParams, 'user_0B5Qt73sZ5tlovtY44fgT');
         
    }
}

export function emailIsValid (email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
    
