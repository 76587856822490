import React, { useContext } from "react";
import './AppLayout.scss';
import { UserSettingsContext } from './contexts/UserSettingsContextManagement'

type appLayoutProps = {
    children?: React.ReactNode
}

 
const AppLayout = (p: appLayoutProps) => {

    const { userSettings } = useContext(UserSettingsContext) 
    const metaNameTheme = document.querySelector('meta[name=theme-color]')
    const body = document.body; 
    
    metaNameTheme && metaNameTheme.setAttribute("content", userSettings.darkTheme ? '#131313' : '#abc8d9')

    const bodyUserSettingsClasses = [ 
        ...(userSettings.darkTheme ? ['user-settings-dark-mode'] : []),
        ...(userSettings.boardTheme ? ['user-settings-board-theme-' + userSettings.boardTheme] : []),
        ...(userSettings.pieceType ? ['user-settings-piece-type-' + userSettings.pieceType] : []),
    ];
    
    body.removeAttribute('class')
    body.classList.add(...bodyUserSettingsClasses);

    return <div className={'page-wrp'}>
        <div className="gradient"></div>
        {p.children}
    </div>

}

export default AppLayout;