export function isJSON (str : string | null | undefined) {
    if(str === null || str === undefined){
        return false;
    }
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export function toJson(str: string | null | undefined) { 
    if(str && isJSON(str)){
        return JSON.parse(str)
    }else{
        return undefined
    }
}