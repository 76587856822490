import React, { RefObject, lazy, Suspense, createRef, useEffect, useState } from "react";

import Modal from "../../components/_atoms/Modal/Modal";

// @ts-ignore
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import { getQuery } from "../../helpers/urlHelper";

const UserSettings = lazy(() => import("../../components/UserSettings/UserSettings"));
const PrivacyPolicy = lazy(() => import("../../components/PrivacyPolicy/PrivacyPolicy"));

type NavBarProps = {
    routes?: {
        path: string,
        text: string,
        icon?: string,
        externalLink?: string,
        isHiddenInMenu?: boolean,
        render?: () => void
    }[];
};

const NavBar = (p: NavBarProps) => {

    const [menuClose, setMenuClose] = useState<boolean>(true)
    const [modalToOpen, setModalToOpen] = useState<{ modal: React.ReactNode, closable?: boolean, noOverlay?: true } | undefined>();

    const toggleMenuClose = () => {
        setMenuClose(!menuClose);
    };

    const handleOnblur = (ev: React.FocusEvent<HTMLInputElement>) => {
        setMenuClose(true);
    };

    const handleMenuClick = (ev: React.MouseEvent<HTMLElement>) => {
        setMenuClose(true);
    };

    useEffect(() => {
        window.onscroll = () => {
            if (window.pageYOffset > 10) {
                setMenuClose(true);
            }
        }
    }, []);

    const handleUserSettings = () => {

        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Settings'}
                isClossable={true}
                setModalToOpen={setModalToOpen}
                preventRootOverflow={true}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => {
                        modalChildref.current.refHandleSaveSettings();
                    }}>Save</button>}
            >
                <UserSettings
                    ref={modalChildref}
                    setModalToOpen={setModalToOpen}
                />
            </Modal></Suspense>, closable: true
        })

    }
    const handlePrivacyPolicy = () => {

        const modalChildref: RefObject<any> = createRef<HTMLDivElement>();
        setModalToOpen({
            modal: <Suspense fallback={<div className={'modal-loading'} />}><Modal
                header={'Privacy Policy'}
                isClossable={true}
                setModalToOpen={setModalToOpen}
                preventRootOverflow={true}
                callToActionsBtns={
                    <button className={'btn-2-xl'} onClick={(ev) => {
                        modalChildref.current.refHandleClose();
                    }}>Close</button>}
            >
                <PrivacyPolicy
                    ref={modalChildref}
                    setModalToOpen={setModalToOpen}
                />
            </Modal></Suspense>, closable: true
        })

    }

    return <div><nav className={'nav-bar ' + (menuClose ? 'close' : 'open')} role="navigation" aria-label="main navigation" onBlur={handleOnblur}>

        <div className={'logoIcon'} role="img" aria-label="Chessdose logoIcon"></div>
        <div className={'logo'} role="img" aria-label="Chessdose logo"></div>

        <button className={'burger-menu-btn mr-sm-1'} onClick={toggleMenuClose} aria-label={(menuClose ? 'Close' : 'Open') + ' the menu'}></button>
        <ul className={'burger-menu-items'}>
            {p.routes && p.routes.map((route, index) => (
                route?.isHiddenInMenu !== true &&
                <li key={'nav-link-' + index} onClick={handleMenuClick}>
                    {route.externalLink ?
                        <a href={route.externalLink} target="_blank" rel="noopener noreferrer">
                            {route.icon && <div className={route.icon}></div>}
                            {route.text}
                        </a>
                        :
                        <NavLink exact activeClassName="is-active" to={route.path + (getQuery('free') ? '?free=true' : '')} >
                            {route.icon && <div className={route.icon}></div>}
                            {route.text}
                        </NavLink>
                    }
                </li>
            ))}
            <li key={'settings'} onClick={handleMenuClick}>
                <button onClick={(ev) => { ev.preventDefault(); handleUserSettings() }} >
                    <div className="icon-preferences"></div>
                    Preferences
                </button>
            </li>
            <li key={'privacy-policy'} onClick={handleMenuClick}>
                <button onClick={(ev) => { ev.preventDefault(); handlePrivacyPolicy() }} >
                    <div className="icon-move-bookmove"></div>
                    Privacy Policy
                </button>
            </li>
        </ul>
    </nav>
        {modalToOpen?.modal && (modalToOpen.modal)}
    </div>
}


export default NavBar;