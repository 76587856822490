import React, { useRef, useEffect } from "react";
import ReactDom from "react-dom";

import "./Modal.scss";
type ModalProps = {
    header?: string,
    headerContent?: React.ReactNode,
    children?: React.ReactNode,
    isClossable?: boolean,
    withNoModalBg?: boolean,
    setModalToOpen?: (modalToOpen?: undefined) => void,
    callToActionsBtns?: React.ReactNode,
    preventRootOverflow?: boolean
};

const Modal = (p: ModalProps) => {

    const $portal = document.getElementById("portal");

    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const $root = document.getElementById("root");
        if ($root) {
            $root.setAttribute("style", "height: 100vh; overflow: hidden;");
        }
        return () => {
            if ($root) {
                $root.setAttribute("style", "height: auto; overflow: auto;");
            }
        };
    }, []);

    if (!$portal) {
        console.log('portal element found')
        return null;
    }

    const closeModal = (ev: React.MouseEvent<HTMLDivElement>) => {
        if (ev.target === overlayRef.current && p.setModalToOpen) {
            p.setModalToOpen(undefined);
        }
    };

    const classNames = [
        'modal',
        ...(p.header ? ['with-header'] : []),
        ...(p.callToActionsBtns ? ['with-call-to-action'] : [])
    ].join(' ');

    const modalBg = [
        'modal-bg',
        ...(p.withNoModalBg ? ['no-bg'] : []),
    ].join(' ');

    return ReactDom.createPortal(<div className={modalBg} ref={overlayRef} {...(p.isClossable && { onMouseDown: (ev: any) => closeModal(ev) })}>
        <div className={classNames}>
            {p.header && <div className='modal-header'>
                <h1>{p.header}</h1>
            </div>}
            {p.headerContent && <div className='modal-header-content'>{p.headerContent}</div>}
            {p.isClossable && p.setModalToOpen &&
                <button className='modal-close' area-label="Close modal"
                    onClick={() => { p.setModalToOpen && p.setModalToOpen(undefined) }}></button>
            }
            <div className={'modal-content' + (p.preventRootOverflow ? ' prevent-root-overflow' : '')}>
                {p.children}
            </div>
            {p.callToActionsBtns && <div className='modal-call-to-action'>{p.callToActionsBtns}</div>}
        </div>
    </div>, $portal);
};

export default Modal;