import React, { useEffect, useState } from "react";

import "./StarRating.scss";

type StarRatingProps = {
     rating: number
     setRating: (rating: number) => void
};

const StarRating = (p: StarRatingProps) => {
  
  const [hover, setHover] = useState(p.rating);

  useEffect(() => {
    setHover(p.rating)
}, [p.rating]);

  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= (hover || p.rating) ? "on" : "off"}
            onClick={() => p.setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(p.rating)}
          > 
          </button>
        );
      })}
    </div>
  );
   
};

export default StarRating;