import React, { useState } from 'react'
import UserSettingsType from "../types/UserSettingsType";
import { toJson } from "../helpers/stringHelper";
import { setLocalStorageVariable } from "../helpers/localeStorageHelper";
const lsUserSettings = toJson(localStorage.getItem('userSettings'));

let preferedColorSchemeDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
 
let defaultUserSettings: UserSettingsType = lsUserSettings ? lsUserSettings : {
    pieceType: 'fontAwesome',
    boardTheme: preferedColorSchemeDark ? 'blue' : 'blue',
    darkTheme: preferedColorSchemeDark ? true : false,
    showHintButton: true,
    enableSounds: true,
    showLastMove: true,
    showLegalMoves: true,
    showBoardNotations: true,
    showNotationOutsideOfBoard: false,
    showEvalutation: true
};

type SettingsContextProviderProps = {
    children?: React.ReactNode
};

export const UserSettingsContext = React.createContext({
    userSettings: defaultUserSettings,
    setUserSettings: (userSettings: UserSettingsType) => { }
})

export const UserSettingsContextProvider = (p: SettingsContextProviderProps) => {

    const handleSetSettings = (userSettings: UserSettingsType) => {
        setState({ ...initState, userSettings: userSettings });
        setLocalStorageVariable('userSettings', JSON.stringify(userSettings));
    }

    const initState = {
        userSettings: defaultUserSettings,
        setUserSettings: handleSetSettings
    }

    const [state, setState] = useState<{
        userSettings: UserSettingsType,
        setUserSettings: (userSettings: UserSettingsType) => void
    }>(initState)

    return (
        <UserSettingsContext.Provider value={state}>
            {p.children}
        </UserSettingsContext.Provider>
    )
}