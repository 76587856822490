 export function getSceenSizeBreakPoint(){
    let innerWitdth = window.innerWidth;
    let breakPoints = { 
        'xs': 0,
        'sm': 576,
        'md': 768,
        'lg': 992,
        'xl': 1200,
        'xxl': 1600
    }
    let breakPoint: string | undefined = undefined
    for (const [key, value] of Object.entries(breakPoints)) {
        if(innerWitdth >= value){
            breakPoint = key;
        }
    }
    return breakPoint ? breakPoint : 'xs';
}