import React, { useContext } from "react";
import "./Piece.scss"; 
import piecesFontAwesome from "./pieceTypes/fontAwesome";
import piecesChessIcons from "./pieceTypes/chessIcons";
import { UserSettingsContext } from "../../contexts/UserSettingsContextManagement";

type PieceProps = { 
  className?: string,
  pieceRef?: React.RefObject<HTMLDivElement >,
  style?: React.CSSProperties,
  type: string,
  isMoveable?: boolean | undefined,
  removePiece?: (from: string) => void,
  getTurn?: () => string,
  activeSquare?: HTMLDivElement,
  setActiveSquare?: (square?: HTMLDivElement) => void,
  squareDraggedOver?: string,
  setSquareDraggedOver?: (square?: string | undefined) => void,
  onlyAllowLegalMoves?: boolean,
  showLegalMoves?: (piece?: HTMLDivElement, square?: string) => void,
  movePiece?: (piece: string, from: string, to: string) => void,
  forcePieceType?: string,
}; 

const Piece = React.forwardRef((p: PieceProps, ref) => {

  const {userSettings} = useContext(UserSettingsContext)
  

  const pieceType = p.type.toLowerCase(); 
  const pieceTypeCollection : {[key: string]: any} = {
    'fontAwesome': piecesFontAwesome,
    'chessIcons': piecesChessIcons  
  };
  
  const pieceTypes = pieceTypeCollection[p.forcePieceType ? p.forcePieceType : (userSettings.pieceType ? userSettings.pieceType : 'fontAwesome')];
  const pieceColor = p.type === p.type.toUpperCase() ? "w" : "b";
  let inTurn = p.getTurn ? p.getTurn() === pieceColor : false;
 
  const handleOnDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.stopPropagation()
    const target = ev.target as HTMLDivElement;
    const parentNode = ev.currentTarget.parentNode as HTMLDivElement;
  
    if (!p.onlyAllowLegalMoves || (p.onlyAllowLegalMoves && inTurn)) {
      p.setActiveSquare && p.setActiveSquare(parentNode);
    }
    /*
    let pic = new Image()
    pic.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    pic.style.visibility = 'hidden';
    ev.dataTransfer.setDragImage(pic, 0, 0)
    */
    ev.dataTransfer.setData("piece", target.id);
    ev.dataTransfer.setData("from", parentNode.id); 
    
    if ((!p.onlyAllowLegalMoves && p.showLegalMoves) || inTurn) {
      p.showLegalMoves && p.showLegalMoves(target, parentNode.id);
    } else {
      p.setActiveSquare && p.setActiveSquare();
      p.showLegalMoves && p.showLegalMoves();
    }
   
  };
 
  const handleOnClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    const target = ev.target as HTMLDivElement;
    const parentNode = ev.currentTarget.parentNode as HTMLDivElement;
    if (!p.onlyAllowLegalMoves || (p.onlyAllowLegalMoves && inTurn)) {
      p.showLegalMoves && p.showLegalMoves(target, parentNode.id);
      p.setActiveSquare && p.setActiveSquare(parentNode);
    }
  };

  const handleDoubleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (p.onlyAllowLegalMoves) {
      return false
    }
    const target = ev.target as HTMLDivElement;
    const parentNode = ev.currentTarget.parentNode as HTMLDivElement;
    p.removePiece && p.removePiece(parentNode.id);
    p.showLegalMoves && p.showLegalMoves(target);
  };

  const handleOnTouchStart = (ev: React.TouchEvent<HTMLDivElement>) => { 
    (document.activeElement as HTMLElement).blur(); 
    p.setSquareDraggedOver && inTurn && p.setSquareDraggedOver(); 
    const target = ev.currentTarget as HTMLDivElement; 
    const parentNode = ev.currentTarget.parentNode as HTMLDivElement;

    if ((!p.activeSquare && !p.onlyAllowLegalMoves) || (p.onlyAllowLegalMoves && p.getTurn && p.getTurn().toString() === target.getAttribute('color'))) {
      p.setActiveSquare && p.setActiveSquare(parentNode);
      p.showLegalMoves && p.showLegalMoves(target, parentNode.id);
    } else if (!p.onlyAllowLegalMoves && p.activeSquare && parentNode.id === p.activeSquare.id) {
      p.removePiece && p.removePiece(parentNode.id);
    }
  };

  const handleOnTouchMove = (ev: React.TouchEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    const touch = ev.touches[0];
    let elementFromPoint = document.elementFromPoint(touch.clientX, touch.clientY) as HTMLDivElement;
    if (elementFromPoint && elementFromPoint.classList.contains('square')) {
      p.setSquareDraggedOver && p.setSquareDraggedOver(elementFromPoint.id)
    } else if (elementFromPoint && elementFromPoint.classList.contains('piece') && elementFromPoint.parentNode) {
      p.setSquareDraggedOver && p.setSquareDraggedOver((elementFromPoint.parentNode as HTMLDivElement).id)
    } else {
      p.setSquareDraggedOver && p.setSquareDraggedOver()
    }
    
  };

  const handleOnTouchEnd = (ev: React.TouchEvent<HTMLDivElement>) => {

    const target = ev.target as HTMLDivElement;
    const parentNode = target.parentNode as HTMLDivElement;
    const from = p.activeSquare && p.activeSquare as HTMLDivElement;
    const to = p.squareDraggedOver ? p.squareDraggedOver : (target.classList.contains('square') ? target.id : (parentNode.classList.contains('square') ? parentNode.id : undefined));
   
    if (p.setSquareDraggedOver) {
      p.setSquareDraggedOver('undefined')
    }
    if (!p.squareDraggedOver && !p.onlyAllowLegalMoves && from) {
      p.removePiece && p.removePiece(from.id);
    }

    if (from && to && from.id !== to) {
      let piece = from.children;
      if (piece[0]) {
        p.movePiece && p.movePiece(piece[0].id, from.id, to);
      }
    } else if (from && to && from.id === to) {
      p.setActiveSquare && p.setActiveSquare(parentNode);
      p.showLegalMoves && p.showLegalMoves(target, parentNode.id);
    }
    return false;
  };
 

  const pieceClassNames = [
    'piece ' + pieceColor + pieceType + (p.className ? ' ' + p.className : ''),
  //  ...((!p.onlyAllowLegalMoves && p.isMoveable) || (p.onlyAllowLegalMoves && p.isMoveable && inTurn) ? ['draggable'] : []),
  ].join(' ');

  return (
    <div  
      className={pieceClassNames} 
      ref={p.pieceRef}
      style={p.style}
       id={p.type} 
      area-label={(p.type === p.type.toUpperCase() ? "White" : "Black") + ' ' + pieceTypes[pieceType].name}
      color={p.type === p.type.toUpperCase() ? "w" : "b"}
      draggable={p.isMoveable}
      onDragStart={p.isMoveable ? (ev) => handleOnDragStart(ev) : void 0}  
      onTouchStart={p.isMoveable ? (ev) => handleOnTouchStart(ev) : void 0}
      onTouchMove={p.isMoveable ? (ev) => handleOnTouchMove(ev) : void 0}
      onTouchEnd={p.isMoveable ? (ev) => handleOnTouchEnd(ev) : void 0}  
      onDoubleClick={p.isMoveable ? (ev) => handleDoubleClick(ev) : void 0}
      onClick={p.isMoveable ? (ev) => handleOnClick(ev) : void 0}
    > 
      <svg viewBox={pieceTypes[pieceType].viewBox}> 
        {pieceTypes[pieceType].svgPaths}
      </svg> 
    </div>
  );
});

/*

 <defs>
        <filter id="shadow">
            <feOffset dx="-12" dy="-12"/>                                                         
            <feGaussianBlur stdDeviation="3"/>                          
            <feComposite operator="out" in="SourceGraphic" result="inverse"/>  
            <feFlood flood-color="#000" flood-opacity="0.7" result="color"/>          
          <feComposite operator="over" in="shadow" in2="SourceGraphic"/>                     
        </filter>
        </defs>
        */
export default Piece;