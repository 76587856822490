import React, { useState  } from "react";

import "./ToggleButton.scss";

type ToggleButtonProps = { 
  toggleBtnRef?: any;
  label?:{title:string, ariaLabel?:{title:string, pos:string}},
  selected: boolean, 
  disabled?:boolean,
  id?:string | number, 
  toggleSelected: (selected : boolean, id?:string) => void 
};

const ToggleButton = (p: ToggleButtonProps ) => { 
    const [selected, setSelected] = useState<boolean>(p.selected);  
    const handleToggleSelected = (ev: React.MouseEvent<HTMLButtonElement> |  React.MouseEvent<HTMLLabelElement>) => {
      setSelected(!selected)
      p.toggleSelected(!selected, p.id?.toString())
    };   

    return (<>
        {
          p.label && 
          <label onClick={(ev) => !p.disabled && handleToggleSelected(ev)} aria-label={p.label.ariaLabel && p.label.ariaLabel.title} data-balloon-length={'large'} data-balloon-pos={p.label.ariaLabel && p.label.ariaLabel.pos} className={'toggle-container-label' + ( p.disabled ? ' disabled' : '' )}>
            {p.label.title}
          </label>
        }
        <button 
          ref={p.toggleBtnRef ? p.toggleBtnRef : null}
          className={`toggle-container ${selected ? "" : " disabled"}`}
          onClick={(ev) => handleToggleSelected(ev)}  
          {...( p.disabled && { disabled: true } )} 
        > 
          <div className={'dialog-button'}></div>
        </button>
      </>
    ); 
} ;

export default ToggleButton;